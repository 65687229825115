.wrapper {
    display: flex;
    justify-content: space-between;
}

.qualityDefinitions, .shiftRange {
    width: calc((100% - 52px)/ 2);
}

.qualityDefinitionsRow, .shiftRangeRow {
    display: flex;
}

.qualityDefinitionsRow, .shiftRangeRow {
    padding-bottom: 36px;
}

.qualityDefinitionsRow:nth-child(2) span, .shiftRangeRow:nth-child(2) span {
    font-weight: 600;
}

.qualityDefinitions span, .shiftRangeRow span {
    width: 30%;
}

.qualityDefinitions span:last-child, .shiftRangeRow span:last-child {
    width: 10%;
    text-align: right;
}

.tableHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 42px;
}

.qualityDefinitions h4, .shiftRange h4 {
    margin: 0;
}

.tableHeader button {
    border: none;
    background: none;
    cursor: pointer;
    color: #2864C8;
    font-weight: bold;
}

.qualityDefinitionsCel, .shiftRangeCel {
    height: 27px;
}

.qualityDefinitionsCel input {
    border: 2px solid #2864C8;
    border-radius: 4px;
    width: 40px;
    font-size: 16px;
    font-family: 'Sora';
}

@media (max-width: 767px) {
    .wrapper {
        display: flex;
        flex-direction: column;
    }

    .qualityDefinitions, .shiftRange {
        width: 100%;
        margin-bottom: 25px;
    }
}