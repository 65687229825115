@import url(https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: 'Sora', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #11111B;
}

button {
  font-family: 'Sora', sans-serif;
  cursor: pointer;
}

input {
  font-family: 'Sora', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #11111B;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}


::-webkit-scrollbar-track {
  background: darkgray;
  border-radius: 2px;
}


::-webkit-scrollbar-thumb {
  background-color: gray; 
  border-radius: 5px;
}


::-webkit-scrollbar-thumb:hover {
  background-color: #11111BBB; 
}

.input-range {
  margin: 37px 35px 37px !important;
  width: auto !important;
}

.input-range__label-container {
  font-family: 'Sora', sans-serif;
  font-size: 14px;
  color: #11111B;
  background: white;
}

.input-range .input-range__slider {
  background-color: white;
  margin-top: -0.8rem;
  border-color: #2864C8;
}

.input-range__track.input-range__track--active {
  background: #2864C8;
}

input[type='checkbox'] {
  margin: 0;
  width: 20px;
  height: 20px;
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  border: 1px solid #BFBFBF;
  box-sizing: content-box;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
}

input[type='checkbox'].large {
  width: 30px;
  height: 30px;
}

input[type='checkbox']:checked {
  background-color: #2864C8;
  border: 1px solid #2864C8;
  color: white;
}

input[type='checkbox']:checked::after {
  content: 'L';
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 6px;
  color: white;
  font-weight: bolder;
  transform: scaleX(-1) rotate(-35deg);
}

input[type='checkbox'].large:checked::after {
  font-size: 21px;
  left: 9px;
}

input[type='radio'] {
  margin: 0;
  width: 20px;
  height: 20px;
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  border: 1px solid #BFBFBF;
  box-sizing: content-box;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

input[type='radio']:checked {
  background-color: white;
  border: 1px solid #2864C8;
  color: white;
}

input[type='radio']:checked::after {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
  background-color: #2864C8;
}
.Score_wrapper__2YLsb {
    border: 1px solid;
    border-radius: 4px;
    padding: 9px 19px 12px 15px;
    height: -webkit-max-content;
    height: max-content;
    min-width: 160px;
    box-sizing: border-box;
    text-align: left;
}

.Score_wrapperSimple__1IJHK {
    border: 1px solid;
    border-radius: 12px;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    text-align: center;
}

.Score_main__2rzsA {
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Score_wrapperSimple__1IJHK .Score_main__2rzsA {
    width: auto;
}

.Score_label__1cNYN {
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    color: #11111B;
    opacity: 0.6;
    margin: 0;
}

.Score_score__Dymku {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
}

.Score_wrapperSimple__1IJHK .Score_score__Dymku.Score_big__3B7dS {
    font-size: 20px;
    line-height: 1;
}

.Score_wrapperSimple__1IJHK .Score_score__Dymku.Score_small__2yuR9 {
    font-size: 11px;
}

@media (max-width: 767px) {
    .Score_wrapper__2YLsb {
      width: calc((100% - 15px) / 2);
      margin-bottom: 15px;
    }
  }
  
.Card_wrapper___aoca {
    padding: 0;
    border: 1px solid #E1E3E6;
    box-sizing: border-box;
    width: calc((100% - (20px * 4)) / 5);
    cursor: pointer;
    border-radius: 13px;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1))
}

.Card_image__jMGH_ {
    border-radius: 5px 5px 0 0;
    width: 100%
}

.Card_legendWrapper__2mbZ5 {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
}

.Card_commentButton__td4in {
    color: #2864C8;
    margin-right: 9px;
    font-size: 18px;
}

.Card_legend__rk8l1 {
    font-weight: normal;
    font-size: 12px;
    color: #11111B;
}

.Card_information__2M78A {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    text-align: left;
}

.Card_specialty__hCbzp {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: block;
}

.Card_crust__1V3xi {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    opacity: 0.7;
    display: block;
    text-transform: capitalize;
}

.Card_imageWrapper__1BCsH {
    position: relative;
    display: flex;
}

.Card_overlayScore__swrgm {
    display: none;
}

.Card_body__2Tm41 {
    padding: 10px 10px;
}

.Card_feedbackProperties__1kkG_ {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Card_hidden__3-Fdb {
    font-weight: bold;
    font-size: 10px;
    line-height: 24px;
    color: red;
    display: block;
}

.Card_qaInspector__N-6e- {
    font-weight: bold;
    font-size: 10px;
    line-height: 24px;
    display: block;
}

.Card_gtCategory__1eq96 {
    font-weight: normal;
    font-size: 10px;
    line-height: 24px;
    color: #11111B;
    opacity: 0.4;
}

@media (max-width: 767px) {
    .Card_wrapper___aoca {
        width: calc((100% - (20px * 1)) / 2);
    }

    .Card_score__3Vx7s {
        display: none;
    }

    .Card_overlayScore__swrgm {
        display: block;
        position: absolute;
        bottom: 5px;
        right: 5px;
        background-color: white;
        border-radius: 12px;
    }
}

.Grid_wrapper__2oGLz {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    padding: 20px 50px;
    box-sizing: border-box;
    grid-gap: 20px;
    gap: 20px;
    padding-bottom: 0;
}

@media (max-width: 767px) {
    .Grid_wrapper__2oGLz {
        padding: 15px;
        padding-bottom: 0;
    }
}

.Breadcrumb_wrapper__2JulI {
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.Breadcrumb_step__3UyxA {
    border-right: 1px solid #E1E3E6;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    padding: 7px 20px;
    color: #11111BAF ;
    min-height: 38px;
    display: flex;
    align-items: center;
}

.Breadcrumb_step__3UyxA.Breadcrumb_small__2ebJv {
    min-height: inherit;
    padding: 0 10px;
}

.Breadcrumb_step__3UyxA.Breadcrumb_small__2ebJv span {
    line-height: inherit;
}

.Breadcrumb_step__3UyxA span {
    text-transform: capitalize;
}

.Breadcrumb_step__3UyxA:first-child {
    padding-left: 0;
}

.Breadcrumb_step__3UyxA:last-child {
    border-right: none;
}

@media (max-width: 767px) {
    .Breadcrumb_step__3UyxA:last-child {
        padding-right: 0;
    }
}
.Select_wrapper__3NvSj {
    position: relative;
    color: #11111B;
}

.Select_boxWrapper__DgDcC {
    background: #FFFFFF;
    border: 1px solid #E1E3E6;
    padding: 6px 15px;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 187px;
}

.Select_boxWrapper__DgDcC.Select_main__jCBIu {
    font-size: 15px;
    line-height: 24px;
    padding: 14px 15px 12px
}

.Select_boxWrapper__DgDcC.Select_main__jCBIu svg {
    font-size: 13px;
}

.Select_boxWrapper__DgDcC.Select_main__jCBIu span {
    font-size: 15px;
}

.Select_boxWrapperLabel__pSb-q {
    position: absolute;
    top: -5.5px;
    left: 10px;
    background-color: white;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    opacity: 0.5;
    padding: 0 4px;
}

.Select_optionsWrapper__24WQB {
    position: absolute;
    background: #FFFFFF;
    border: 1px solid #E1E3E6;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    z-index: 1;
}
.Select_selectionWrapper__wVWHr {
    padding: 20px 15px 15px;
}

.Select_selection__3-YG2 {
    max-height: 275px;
    padding-bottom: 15px;
    border-bottom: 1px solid #E1E3E6;
    margin-bottom: 15px;
}

.Select_options__2abjX {
    max-height: 275px;
}

.Select_selection__3-YG2, .Select_options__2abjX {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
}

.Select_option__1iwa7 {
    width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 5px 5px 10px;
    box-sizing: border-box;
    margin-right: 10px;
}

.Select_option__1iwa7:focus-within {
    background-color: #eaffea;
    mix-blend-mode: multiply;
    border-radius: 4px;
}

.Select_option__1iwa7.Select_selected__3QGJx {
    background-color: #EAF2FF;
    mix-blend-mode: multiply;
    border-radius: 4px;
}

.Select_option__1iwa7.Select_disabled___u2K0 {
    background-color: #d5d5d5;
    mix-blend-mode: multiply;
    border-radius: 4px;
}

.Select_option__1iwa7 label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    width: calc(100% - 20px);
    text-align: left;

}

.Select_option__1iwa7:focus-within label {
    color: #28c838;
}

.Select_option__1iwa7.Select_selected__3QGJx label {
    color: #2864C8;
}

.Select_option__1iwa7.Select_disabled___u2K0 label {
    text-decoration: line-through;
}

.Select_buttonsWrapper__2lsYS {
    display: flex;
    border-top: 1px solid #E1E3E6;
}

.Select_selectAll__mp5XY {
    width: 50%;
    height: 56px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: 1px solid #E1E3E6;
}

.Select_selectAll__mp5XY label {
    color: #11111B7D;
    text-align: right;
    width: calc(100% - 20px);
    cursor: pointer;
}

.Select_clearApply__xlBDX {
    width: 50%;
    height: 56px;
    /* padding: 0 20px 0; */
    display: flex;
    /* padding: 20px 20px; */
    justify-content: space-between;
}

.Select_clearApply__xlBDX button {
    width: 50%;
    font-family: 'Sora', sans-serif;
    border: none;
    background: none;
    color: #11111B7D;
    cursor: pointer;
}

.Select_clearApply__xlBDX button, .Select_selectAll__mp5XY label {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
}

.Select_clearApply__xlBDX button:hover, .Select_selectAll__mp5XY label:hover {
    color: #2864C8;
}

.Select_selectMobile__2HMjU {
    display: none;
}

.Select_highlight__3wmb1 {
    background-color: #4bd1ff !important;
}

@media (max-width: 767px) {
    .Select_boxWrapper__DgDcC {
        width: 100%;
        color: #11111B;
        height: 40px;
        font-size: 13px;
    }

    .Select_boxWrapper__DgDcC.Select_main__jCBIu {
        height: 52px;
        font-size: 13px;
        line-height: 24px;
        padding: 14px 15px 12px;
    }

    .Select_boxWrapper__DgDcC.Select_main__jCBIu svg {
        font-size: 13px;
    }
    
    .Select_boxWrapper__DgDcC.Select_main__jCBIu span {
        font-size: 13px;
    }

    .Select_selectMobile__2HMjU {
        display: block;
    }

    .Select_selectDesktop__V7II8 {
        display: none;
    }
}
.ModalWrapper_background__1als6 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    overflow-y: auto;
}

.ModalWrapper_wrapper__2bZJI {
    position: relative;
    background-color: white;
    width: 550px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 13px;
    box-shadow: 0px 0px 30px 5px rgb(0 0 0 / 30%);
}
.Toggle_wrapper__141Pw {
    display: flex;
    background-color: #EAF2FF;
    border-radius: 44px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.Toggle_wrapper__141Pw span {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    opacity: 0.5;
    text-transform: uppercase;
}

.Toggle_mode__1mlsH {
    display: flex;
    padding: 11px  22px;
    cursor: pointer;
}

.Toggle_mode__1mlsH.Toggle_active__lB9VL {
    background-color: #2864C8;
    border-radius: 44px;
}

.Toggle_mode__1mlsH.Toggle_active__lB9VL span {
    color: white;
    opacity: 1;
}

.Toggle_singleModeToggle__15Zqs {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.Toggle_singleModeToggle__15Zqs .Toggle_wrapper__141Pw {
    background-color: #d3d3d3;
}

.Toggle_singleModeToggle__15Zqs .Toggle_wrapper__141Pw.Toggle_active__lB9VL {
    background-color: #2864C8;
}

.Toggle_singleModeToggle__15Zqs .Toggle_mode__1mlsH {
    display: flex;
    padding: 17px  10px;
    cursor: pointer;
}

.Toggle_singleModeToggle__15Zqs .Toggle_mode__1mlsH.Toggle_active__lB9VL {
    border-radius: 44px;
    background-color: white;
    padding: 15px 15px;
    border: 2px solid #6e6e6e;
}

.Toggle_singleModeToggle__15Zqs span {
    font-size: 12px;
}

.ProgressBar_information__2Pu2O {
    display: flex;
    justify-content: space-between;
}

.ProgressBar_name__wgoxy {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
}

.ProgressBar_score__1MhSI {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #2864C8;
}

.ProgressBar_bar__17OeD {
    width: 100%;
    background-color: #EAF2FF;
    border-radius: 50px;
    height: 7px;
}

.ProgressBar_filler__PG5dZ {
    height: 100%;
    background-color: #2864C8;
    border-radius: inherit;
    text-align: right
}

.ProgressBar_label__3aEBR {
    padding: 5;
    color: white;
    font-weight: bold
}

.ProgressBar_scoreWrapper__1KaJL {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}
.wrapper {
    padding-right: 5px;
}

.wrapper .input-range {
    margin: 0 !important;
    margin-right: 11px !important;
    width: auto !important;
}

.wrapper .input-range__label-container {
    display: none;
    font-family: 'Sora', sans-serif;
    font-size: 14px;
    color: #11111B;
    background: white;
}

.wrapper  .input-range__slider {
    background-color: white;
    margin-top: -14px;
    margin-left: -10px;
    border-color: #2864C8;
    height: 19px;
    width: 19px;
}

.wrapper .input-range__track.input-range__track--active {
    background: #2864C8;
}

.wrapper .input-range__track {
    height: 7px !important;
}

.wrapper .input-range--disabled .input-range__slider {
    display: none;
}
.PizzaFeedback_wrapper__zFKHX {
    display: flex;
    max-height: 685px;
}

.PizzaFeedback_rightPanel__8waQv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 40px 23px 31px;
}

.PizzaFeedback_imageWrapper__Podie {
    position: relative;
}

.PizzaFeedback_imageWrapper__Podie button {
    position: absolute;
}

.PizzaFeedback_image__N853O {
    width: 100%;
    max-width: 685px;
}

.PizzaFeedback_barsWrapper__1pJDB {
    width: 400px;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 25px;
    padding: 0 0 51px 0;
    border-bottom: 1px solid #E1E3E6;
    overflow-y: auto;
}

.PizzaFeedback_editScoring___dPP6 {
    background: #2864C8;
    border-radius: 77px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: white;
    padding: 11px 0;
    box-shadow: none;
    border: none;
    cursor: pointer;
}

.PizzaFeedback_editScoringIcon__2QGdO {
    margin-right: 9px;
}

.PizzaFeedback_imageButton__3kZMO {
    border-radius: 25px;
    border: 1px solid #E1E3E6;
    background-color: white;
    color: #2864C8;
    padding: 9px;
    height: 21px;
    box-sizing: content-box;
    width: 21px;
    font-size: 19px;
    top: 20px;
    cursor: pointer;
}

.PizzaFeedback_imageButton__3kZMO svg {
    margin: 0;
}

.PizzaFeedback_commentButton__1DCt_ {
    right: 75px;
}

.PizzaFeedback_expandButton__3ubVb {
    right: 20px;
}

.PizzaFeedback_mobileScore__2nlDX {
    display: none;
}

.PizzaFeedback_toggles__38ax-,
.PizzaFeedback_marks__1y9e7 {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    grid-gap: 10px;
    gap: 10px;
}

.PizzaFeedback_marks__1y9e7 span {
    text-align: left;
}

.PizzaFeedback_controls__r4TYG {
    display: flex;
    flex-direction: column;
    max-height: 622px;
}

.PizzaFeedback_deltaScore__2vuwy {
    font-weight: bold;
}

@media (max-width: 767px) {
    .PizzaFeedback_wrapper__zFKHX {
        flex-direction: column;
        overflow: visible;
        max-height: none;
    }

    .PizzaFeedback_rightPanel__8waQv {
        overflow: visible;
        padding: 0 20px 0;
        margin-bottom: 20px;
    }

    .PizzaFeedback_barsWrapper__1pJDB {
        width: 100%;
        max-height: none;
        padding-bottom: 13px;
        margin-bottom: 20px;
    }

    .PizzaFeedback_mobileScore__2nlDX {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .PizzaFeedback_mobileScore__2nlDX span {
        text-transform: uppercase;
    }

    .PizzaFeedback_pizzaToggle__249WA {
        position: relative;
        bottom: inherit;
        left: inherit;
        display: flex;
        justify-content: center;
    }

    .PizzaFeedback_toggles__38ax-,
    .PizzaFeedback_marks__1y9e7 {
        align-items: flex-start;
        padding-bottom: 40px;
    }

    .PizzaFeedback_scoreWrapper__2DhBF {
        display: flex;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
    }
}
.Table_information__3KmJD {
    display: flex;
    justify-content: space-between;
}

.Table_name__1Y85l {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
}

.Table_score__3X87j {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #2864C8;
}

.Table_table__sjKoM {
    width: 100%;
    border-collapse: collapse;
}

.Table_table__sjKoM tr th {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 10px;
    color: #676767;
    background-color: #EAF2FF;
    width: 12.5%;
    border: 1px solid #D0D0D0;
}

.Table_table__sjKoM tr td {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 20px;
    width: 12.5%;
    border: 1px solid #D0D0D0;
}
.PizzaDetail_wrapper__1bMB2 {
    display: flex;
    max-height: 685px;
}

.PizzaDetail_rightPanel__2w9Im {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 40px 23px 31px;
}

.PizzaDetail_imageWrapper__1aKUC {
    position: relative;
}

.PizzaDetail_imageWrapper__1aKUC button {
    position: absolute;
}

.PizzaDetail_image__1-1om {
    width: 100%;
    max-width: 685px;
}

.PizzaDetail_tablesWrapper__236Zt {
    width: 395px;
    display: flex;
    flex-direction: column;
    grid-gap: 25px;
    gap: 25px;
    margin-top: 25px;
    padding: 0 5px 51px 0;
    border-bottom: 1px solid #E1E3E6;
    max-height: 520px;
    overflow-y: auto;
}

.PizzaDetail_editScoring__2LbrO {
    background: #2864C8;
    border-radius: 77px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: white;
    padding: 11px 0;
    box-shadow: none;
    border: none;
    cursor: pointer;
}

.PizzaDetail_editScoringIcon__1RAOw {
    margin-right: 9px;
}

.PizzaDetail_imageButton__3IRy2 {
    border-radius: 25px;
    border: 1px solid #E1E3E6;
    background-color: white;
    color: #2864C8;
    padding: 9px;
    height: 21px;
    box-sizing: content-box;
    width: 21px;
    font-size: 19px;
    top: 20px;
    cursor: pointer;
}

.PizzaDetail_imageButton__3IRy2 svg {
    margin: 0;
}

.PizzaDetail_commentButton__1Y8OQ {
    right: 75px;
}

.PizzaDetail_expandButton__15qwB {
    right: 20px;
}

.PizzaDetail_mobileScore__1bC-Y {
    display: none;
}

@media (max-width: 767px) {
    .PizzaDetail_wrapper__1bMB2 {
        flex-direction: column;
        overflow: visible;
        max-height: none;
    }

    .PizzaDetail_rightPanel__2w9Im {
        overflow: visible;
        padding: 0 20px 0;
        margin-bottom: 20px;
    }

    .PizzaDetail_tablesWrapper__236Zt {
        width: 100%;
        max-height: none;
        padding-bottom: 13px;
        margin-bottom: 20px;
    }

    .PizzaDetail_mobileScore__1bC-Y {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .PizzaDetail_mobileScore__1bC-Y span {
        text-transform: uppercase;
    }
}
.CardDetail_background__FhOSH {
    position: fixed;
    top: 48px;
    left: 0;
    width: 100%;
    height: calc(100vh - 48px);
    background-color: #00000099;
    z-index: 2;
    display: flex;
    justify-content: center;
    overflow-y: scroll;
}

.CardDetail_wrapper__3M331 {
    background-color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    border-radius: 13px;
    overflow: hidden;
}

.CardDetail_header__38kPd {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E1E3E6;
}

.CardDetail_title__3uFa4 {
    display: flex;
}

.CardDetail_specialty__2M6ei {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 20px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding: 0 30px;
    border-right: 1px solid #E1E3E6;
}

.CardDetail_legend__3wcmO {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 24px;
    opacity: 0.4;
    display: flex;
    align-items: center;
    padding: 0 30px;
    border-right: 1px solid #E1E3E6;
}

.CardDetail_qaInspector__iQFUP {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    border-right: 1px solid #E1E3E6;
}

.CardDetail_closeButton__3dkXi {
    padding: 25px 30px;
    border-left: 1px solid #E1E3E6;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.CardDetail_secondHeader__21ip_ {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 40px;
    border-bottom: 1px solid #E1E3E6;
    position: relative;
    z-index: 2;
    grid-gap: 15px;
    gap: 15px;
}

.CardDetail_toggleScore__3iG8g {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 93px;
    gap: 93px;
}

.CardDetail_body__1WXZy {
    position: relative;
    z-index: 1;
}

.CardDetail_editHeader__3Nezd {
    background: #2864C8;
    border-radius: 77px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: white;
    padding: 11px 0;
    box-shadow: none;
    border: none;
    cursor: pointer;
    min-width: 150px;
    height: 18px;
    box-sizing: content-box;
}

.CardDetail_editHeaderIcon__3dg_D {
    margin-right: 9px;
}

.CardDetail_breadcrumbEdit__3envQ {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
}

.CardDetail_scoreWrapper__1u_eN {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.CardDetail_deltaScore__2rpet {
    font-weight: bold;
}

@media (max-width: 767px) {
    
    .CardDetail_background__FhOSH {
        top: 68px;
    }
    
    .CardDetail_title__3uFa4 {
        padding-top: 15px;
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        justify-content: center;
    }

    .CardDetail_closeButton__3dkXi {
        padding: 25px;
        height: auto;
    }

    .CardDetail_secondHeader__21ip_ {
        padding: 20px;
        flex-direction: column;
    }

    .CardDetail_scoreWrapper__1u_eN {
        display: none;
    }

    .CardDetail_toggleScore__3iG8g {
        margin-top: 15px;
    }

    .CardDetail_specialty__2M6ei, .CardDetail_legend__3wcmO, .CardDetail_qaInspector__iQFUP {
        padding: 0;
        text-align: left;
        border-right: none;
    }

    .CardDetail_specialty__2M6ei, .CardDetail_qaInspector__iQFUP {
        font-size: 17px;
    }

    .CardDetail_breadcrumbEdit__3envQ {
        flex-direction: column;
    }
}
.LoadingScreen_loadingScreen__kcB7U {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    /*max-height: 100vh;*/
    left: 0;
    top: 0;
    border-radius: 13px;
}

.LoadingScreen_adaptable__3ivfB {
    position: absolute;
}

.LoadingScreen_spinnerWrapper__2htqi {
    width: 85px;
    height: 85px;
    background: rgba(242, 242, 242, 0.8);
    border-radius: 14px;
    color: white;
    font-weight: 400;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.LoadingScreen_spinnerWrapper__2htqi span {
    width: 44px;
    height: 44px;
    border: 4px solid #2864C8;
    border-bottom: 4px solid rgba(242, 242, 242, 0);
    border-radius: 50%; /* Safari */
    animation: LoadingScreen_spin__hdtPp 2s linear infinite;
}
  
@keyframes LoadingScreen_spin__hdtPp {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.Range_wrapper__3CZfu {
    position: relative;
    color: #11111B;
}

.Range_boxWrapper__2y0ze {
    background: #FFFFFF;
    border: 1px solid #E1E3E6;
    padding: 6px 15px;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 187px;
}

.Range_rangeWrapper__qSNmI {
    position: absolute;
    background: #FFFFFF;
    border: 1px solid #E1E3E6;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    z-index: 1;
    width: 360px;
}

.Range_buttonsWrapper__3zS_W {
    display: flex;
    border-top: 1px solid #E1E3E6;
}

.Range_clearApply__2TUj2 {
    width: 100%;
    height: 56px;
    padding: 0;
    display: flex;
    justify-content: space-around;
}

.Range_clearApply__2TUj2 button {
    font-family: 'Sora', sans-serif;
    border: none;
    background: none;
    color: #11111B7D;
    width: 50%;
    cursor: pointer;
}

.Range_clearApply__2TUj2 button {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
}

.Range_clearApply__2TUj2 button:hover {
    color: #2864C8;
}

.Range_boxWrapperLabel__2lwrq {
    position: absolute;
    top: -5.5px;
    left: 10px;
    background-color: white;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    opacity: 0.5;
    padding: 0 4px;
}

.Range_highlight__3yd5R {
    background-color: #4bd1ff !important;
}

@media (max-width: 767px) {
    .Range_boxWrapper__2y0ze {
        width: 100%;
        font-size: 13px;
    }
}
.Filters_wrapper__1r0CW {
    display: flex;
    flex-direction: column;
}

.Filters_secondaryFilters__2IMtj {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    padding: 7.5px 0 30px 50px;
    border-bottom: 1px solid #E1E3E6;
}

.Filters_mainFilters__3V8FX {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    padding: 36px 0 15px 50px;
}

@media (max-width: 767px) {
    .Filters_mainFilters__3V8FX, .Filters_secondaryFilters__2IMtj {
        padding: 26px 15px 23px;
        flex-wrap: wrap;
    }

    .Filters_secondaryFilters__2IMtj {
        padding-top: 0;
    }

    .Filters_mainFilters__3V8FX > div, .Filters_secondaryFilters__2IMtj > div {
        width: calc((100% - 15px) / 2)
    }
}
.DateSelect_wrapper__1gLAO {
    position: relative;
}

.DateSelect_boxWrapper__1iPIM {
    background: #FFFFFF;
    border: 1px solid #E1E3E6;
    padding: 6px 15px;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 187px;
}

.DateSelect_boxWrapper__1iPIM.DateSelect_main__2U079 {
    font-size: 24px;
    line-height: 24px;
    padding: 14px 15px 12px
}

.DateSelect_boxWrapper__1iPIM.DateSelect_main__2U079 span {
    font-size: 15px;
}

.DateSelect_boxWrapper__1iPIM.DateSelect_main__2U079 svg {
    font-size: 13px;
}

.DateSelect_boxWrapperLabel__2qV-l {
    position: absolute;
    top: -5.5px;
    left: 10px;
    background-color: white;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    opacity: 0.5;
    padding: 0 4px;
}

.DateSelect_datePickerWrapper__21oqh {
    position: absolute;
    background: #FFFFFF;
    border: 1px solid #E1E3E6;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);;
    border-radius: 13px;
    z-index: 1;
    width: -webkit-min-content;
    width: min-content;
}

.DateSelect_buttonsWrapper__Ut4gR {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #E1E3E6;
    padding: 16px;
}

.DateSelect_daysCount__3o5EH {
    font-size: 16px;
    color: #8E8E93;
}

.DateSelect_clearApply__1sz2l {
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 14px;
    line-height: 22px;
    background: #2864C8;
    padding: 8px 30px;
}

.DateSelect_clearApply__1sz2l:hover {
    opacity: 0.7;
}

.DateSelect_rangeCalendarSelector__9zsaW {
    display: flex;
    width: -webkit-max-content;
    width: max-content;
}

.DateSelect_rangeCalendarWrapper__CDM4Z {
    width: 576px;
    display: flex;
}

.DateSelect_rangeCalendar__St1vU {
    width: 100%;
    box-sizing: border-box;
}

.DateSelect_calendar__Fi4PD {
    padding: 20px 32px;
    box-sizing: border-box;
}

.DateSelect_selectMobile__xuvmB {
    display: none;
}

.DateSelect_selectDesktop__3dtD2 .DateSelect_datePicker-wrapper__1B-7R{
    width: 720px;
}

.DateSelect_customPeriods__1cqW_ {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.DateSelect_customPeriods__1cqW_ span {
    font-weight: bold;
    padding: 15.5px 0;
    padding-left: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    width: 100%;
    box-sizing: border-box;
}

.DateSelect_customPeriods__1cqW_ button {
    font-size: 14px;
    padding: 10px 16px;
}

.DateSelect_customPeriods__1cqW_ button {
    background: none;
    width: 100%;
    font-style: normal;
    line-height: 18px;
    color: #11111B;
    box-shadow: none;
    border: none;
    cursor: pointer;
    text-align: left;
}

.DateSelect_customPeriods__1cqW_ button:hover {
    background: #2864C8;
    color: white;
}

.DateSelect_rangeHelp__3pu-n {
    width: 100%;
    text-align: center;
    padding-top: 10px;
    margin-bottom: -10px;
}

.DateSelect_rangeHelp__3pu-n span {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px
}

.DateSelect_loading__1T8Yx {
    top: 0;
    left: 0;
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.9);
}

.DateSelect_selectionLabel__2Zs2u {
    display: flex;
}

@media (min-width: 767px) {
    .DateSelect_boxWrapper__1iPIM.DateSelect_range__1SdUx {
        display: inline-flex;
    }
}

@media (max-width: 767px) {
    .DateSelect_wrapper__1gLAO {
        width: calc((100% - 15px) / 2);
        width: 100%;
    }

    .DateSelect_boxWrapper__1iPIM {
        max-width: 100% !important;
        width: 100%;
        color: #11111B;
    }

    .DateSelect_selectMobile__xuvmB {
        max-width: 100%;
    }

    .DateSelect_boxWrapper__1iPIM.DateSelect_main__2U079 {
        height: 52px;
        font-size: 13px;
        line-height: 24px;
        padding: 14px 15px 12px;
    }

    .DateSelect_boxWrapper__1iPIM.DateSelect_main__2U079 svg {
        font-size: 13px;
    }
    
    .DateSelect_boxWrapper__1iPIM.DateSelect_main__2U079 span {
        font-size: 13px;
    }

    .DateSelect_selectDesktop__3dtD2.DateSelect_range__1SdUx {
        display: flex;
    }

    .DateSelect_rangeCalendarWrapper__CDM4Z {
        flex-direction: column;
        width: 277px;
    }

    .DateSelect_rangeCalendarSelector__9zsaW {
        flex-direction: column;
        width: -webkit-fill-available;
        align-items: center;
    }

    .DateSelect_datePickerWrapper__21oqh {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    .DateSelect_customPeriods__1cqW_ {
        border-right: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        justify-content: center;
        flex-direction: row;
    }

    .DateSelect_customPeriods__1cqW_ button {
        width: auto;
    }

    .DateSelect_selectionLabel__2Zs2u {
        font-size: 13px;
    }

    .DateSelect_boxWrapper__1iPIM {
        font-size: 13px;
    }
}
.kwali-calendar,
.kwali-range-calendar {
    width: 100%;
    border: none !important;
    border-radius: 0 !important;
    font-family: 'Sora', sans-serif !important;
}

.kwali-calendar .react-datepicker__month-container {
    width: 224px;
}

.kwali-calendar .react-datepicker__header__dropdown,
.kwali-range-calendar .react-datepicker__header__dropdown {
    display: flex !important;
    justify-content: center !important;
    grid-gap: 25px !important;
    gap: 25px !important;
    font-size: 15px !important;
    font-style: normal;
    font-weight: normal;
    height: 31px;
    align-items: flex-start;
}

.kwali-calendar .react-datepicker__current-month {
    display: none !important;
}

.kwali-calendar .react-datepicker__header,
.kwali-range-calendar .react-datepicker__header {
    background: white !important;
    border: none !important;
}

.kwali-calendar .react-datepicker__week,
.kwali-calendar .react-datepicker__day-names,
.kwali-range-calendar .react-datepicker__week,
.kwali-range-calendar .react-datepicker__day-names {
    display: flex !important;
    justify-content: space-between !important;
    font-size: 14px;
}

.kwali-calendar .react-datepicker__day-name,
.kwali-range-calendar .react-datepicker__day-names .react-datepicker__day-name{
    font-size: 15px;
    font-weight: 600;
    color: #11111b !important;
}

.kwali-calendar .react-datepicker__week,
.kwali-range-calendar .react-datepicker__week {
    font-size: 14px;
}

.kwali-calendar .react-datepicker__month-read-view--down-arrow,
.kwali-range-calendar .react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
    width: 4px !important;
    height: 4px !important;
    top: 5px !important;
}

.kwali-calendar .react-datepicker__month,
.kwali-range-calendar .react-datepicker__month {
    margin: 0 !important;
    display: flex !important;
    flex-direction: column !important;
}
.kwali-calendar .react-datepicker__day,
.kwali-range-calendar .react-datepicker__day {
    line-height: 1;
    margin: 0;
    margin-bottom: 2px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.kwali-calendar .react-datepicker__year-dropdown,
.kwali-range-calendar .react-datepicker__year-dropdown,
.react-datepicker__month-dropdown {
    background: white !important;
    font-weight:  400 !important;
}

.react-datepicker__month-read-view,
.react-datepicker__year-read-view {
    visibility: inherit !important;
}

.kwali-calendar .react-datepicker__day--keyboard-selected {
    background-color: white !important;
    color: #11111b;
}

.kwali-range-calendar .react-datepicker__day--keyboard-selected {
    background-color: white !important;
    color: #11111b;
}

.kwali-calendar .react-datepicker__day--selected {
    background-color: #2863c8 !important;
    color: white !important;
    border-radius: 50px !important;
}

.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: #2864C8 !important;
    border-radius: 3px !important;
}


.kwali-range-calendar .react-datepicker__day--in-range {
    background-color: rgba(0, 0, 0, 0.05) !important;
    color: #11111b !important;
    border-radius: 0 !important;
}


.kwali-range-calendar .react-datepicker__day:hover,
.kwali-range-calendar .react-datepicker__day--range-start,
.kwali-range-calendar .react-datepicker__day--range-end,
.kwali-calendar .react-datepicker__day--in-range {
    background-color: #2863c8 !important;
    color: white !important;
    border-radius: 50px !important;
}


.dont-show-selection .kwali-range-calendar .react-datepicker__day--in-range {
    background-color: white !important;
    color: #11111b !important;
}

.kwali-calendar .react-datepicker__day--outside-month,
.kwali-range-calendar .react-datepicker__day--outside-month {
    opacity: 0 !important;
    pointer-events: none !important; 
}

.kwali-range-calendar .react-datepicker__month-container .react-datepicker__current-month {
    display: block !important;
    height: 31px !important;
    font-weight: normal;
    font-size: 17px;
}

.kwali-range-calendar .react-datepicker__month-container .react-datepicker__header__dropdown--scroll {
    position: absolute;
    top: -25px;
    left: 179px;
    z-index: 2;
}

.kwali-range-calendar .react-datepicker__month-dropdown {
    width: 125% !important;
}

.kwali-range-calendar .react-datepicker__year-dropdown {
    width: 80% !important;
}

.kwali-calendar .react-datepicker__day--disabled {
    cursor: default !important;
    color: #ccc !important;
    background-color: white !important;
}

.kwali-range-calendar .react-datepicker__day--disabled {
    cursor: default;
    color: #ccc !important;
}

.kwali-range-calendar .react-datepicker__navigation--previous {
    left: 32px;
    top: 28px;
}

.kwali-range-calendar .react-datepicker__navigation--next {
    right: 32px;
    top: 28px;
}

.kwali-range-calendar .react-datepicker__day--selecting-range-start,
.kwali-range-calendar .react-datepicker__day--selecting-range-end {
    background-color: #2863c8 !important;
    color: white !important;
    border-radius: 50px !important;
}

@media (max-width: 767px) {
    .kwali-range-calendar .react-datepicker__month-container {
        width: 225px;
        padding: 25px 25px 25px;
    }
    .kwali-calendar .react-datepicker__month-container {
        width: 225px;
        padding: 0 25px;
    }
    .kwali-range-calendar .react-datepicker__month-container:last-child {
        padding-top: 0;
    }
}

@media (min-width: 767px) {    
    .kwali-range-calendar .react-datepicker__month-container {
        width: 224px;
        padding: 37px 32px 35px;
    }
}
.Summary_wrapper__COPr1 {
  width: 100%;
  padding: 30px 50px;
  box-sizing: border-box;
  text-align: left;
}

.Summary_title__3gn5R {
  display: block;
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #11111B;
  opacity: 0.7;
}

.Summary_scoreCards__1Yrvt {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 8%);
}

.Summary_featuresRow__b26wx {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 22px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
  border-radius: 13px;
  width: 100%;
}

.Summary_feature__sW00t {
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-bottom: 16px;
  padding-top: 16px;
}

.Summary_featureContent__3RU22 {
  display: flex;
  width: 100%;
  padding-left: 30%;
}

.Summary_featureImg__1ousx {
  display: flex;
  align-items: center;
  margin-right: 14px;
}

.Summary_featureDetail__1OnPN {
  display: flex;
  flex-direction: column;
}

.Summary_featureLabel__23k4H {
  font-size: 15px;
  color: #8E8E93;
  line-height: 20px;
}

.Summary_featureValue__2s0O7 {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}


@media (max-width: 767px) {
  .Summary_feature__sW00t {
    width: 100%;
  }

  .Summary_wrapper__COPr1 {
    padding: 15px;
    flex-wrap: wrap;
  }

  .Summary_scoreCards__1Yrvt {
    flex-wrap: wrap;
    justify-content: space-between;
    grid-gap: inherit;
    gap: inherit;
  }
}

.FeedbackDialog_background__1VN31 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #00000099;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.FeedbackDialog_wrapper__2Gent {
    background-color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

.FeedbackDialog_dialogHeader__2rs08 {
    display: flex;
    justify-content: flex-end;
    padding: 25px 25px 13px 25px;
}

.FeedbackDialog_closeButton__Qtx9r {
    width: 12px;
    height: 12px;
    cursor: pointer;
}

.FeedbackDialog_dialogTitle__zIb-C {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 20px;
    padding-bottom: 15px;
}

.FeedbackDialog_dialogBody__2S4ub {
    padding: 15px 40px;
}

.FeedbackDialog_dialogBody__2S4ub textarea {
    width: 320px;
    font-family: 'Sora', sans-serif !important;
    border: 1px solid #E1E3E6;
    box-sizing: border-box;
    border-radius: 4px;
}

.FeedbackDialog_dialogFooter__1V_Xy {
    padding: 15px 40px 30px;
}

.FeedbackDialog_dialogFooter__1V_Xy button {
    width: 100%;
    background: #2864C8;
    border-radius: 77px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: white;
    box-shadow: none;
    border: none;
    cursor: pointer;
    padding: 14px 0;
}
.Dashboard_summaryGrid__1IVrC {
    background: #F7F7FA;
}

.Dashboard_commentedFilter__r0khd {
    display: flex;
    justify-content: flex-start;
    padding: 0 50px;
}

.Dashboard_commentedFilter__r0khd label {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    opacity: 0.7;
}

.Dashboard_commentedFilter__r0khd input {
    margin-right: 11px;
}

@media (max-width: 767px) {
    .Dashboard_commentedFilter__r0khd {
      padding: 15px 15px;
    }
}
.Report_wrapper__tdYlD {
    background: white;
    text-align: left;
    max-width: 1140px;
    margin: auto;
    padding: 20px 0 220px;
}

.Report_col1__2xkXf, .Report_col2__2ZHcw, .Report_col2WithTitle__3CYTo {
    margin-bottom: 46px;
}

.Report_col2__2ZHcw, .Report_col3__35xPo, .Report_col2WithTitle__3CYTo {
    display: flex;
    justify-content: space-between;
}

.Report_col1__2xkXf > div {
    position: relative;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
    border-radius: 13px;
    overflow: hidden;
}

.Report_col2__2ZHcw > div {
    width: calc((100% - 20px) / 2);
    position: relative;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
    border-radius: 13px;
    overflow: hidden;
}

.Report_col2WithTitle__3CYTo > div {
    width: calc((100% - 20px) / 2);
}

.Report_col2WithTitle__3CYTo > div > div {
    position: relative;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
    border-radius: 13px;
    overflow: hidden;
}

.Report_col3__35xPo > div {
    width: calc((100% - 40px) / 3);
    position: relative;
}

.Report_aggregatedChart__35ii6 {
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
    border-radius: 13px;
    overflow: hidden;
    margin-bottom: 46px;
}

.Report_aggregatedChartHeader__31lEY {
    display: flex;
    grid-gap: 117px;
    gap: 117px;
    align-items: center;
    margin-top: 25px;
}

.Report_aggregatedChartTitle__-EbNT {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin-left: 30px;
}

.Report_aggregatedChart__35ii6 .Report_charts__1bT8B {
    display: flex;
    justify-content: space-between;
}

.Report_aggregatedChart__35ii6 .Report_charts__1bT8B > div {
    width: calc((100% - 20px) / 2);
    position: relative;
}



.Report_tooltip__1VVSS {
    position: fixed;
    z-index: 10;
    box-sizing: border-box;
}

.Report_tooltiptext__etIqB {
    visibility: visible;
    width: 130px;
    background-color: #555;
    color: #fff;
    text-align: left;
    border: 1px solid white;
    font-size: 13px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 130%;
    left: 85%;
    margin-left: -140%;
    opacity: 1;
    transition: opacity 0.3s;
    padding: 5px 5px 5px 1px;
}

.Report_tooltiptext__etIqB::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}

.Report_leftWall__2jfv2 {
    height: calc(100% + 16px);
    width: 8px;
    position: absolute;
    right: 100%;
    top: -8px;
    background: red;
}

.Report_rightWall__Zsuw1 {
    height: calc(100% + 16px);
    width: 8px;
    position: absolute;
    left: 100%;
    top: -8px;
    background: red;
}

.Report_bottomWall__3_yUo {
    height: 8px;
    width: calc(100% + 16px);
    position: absolute;
    top: 100%;
    left: -8px;
    background: red;
}

.Report_topWall__1Xyk5 {
    height: 8px;
    width: calc(100% + 16px);
    position: absolute;
    bottom: 100%;
    left: -8px;
    background: red;
}


@media (max-width: 767px) {
    .Report_wrapper__tdYlD {
        padding: 25px 40px 0 10px;
    }

    .Report_aggregatedChart__35ii6 {
        margin-bottom: 40px;
    }

    .Report_aggregatedChart__35ii6 .Report_charts__1bT8B {
        flex-wrap: wrap;
    }

    .Report_aggregatedChart__35ii6 .Report_charts__1bT8B > div {
        width: 100%;
    }

    .Report_aggregatedChartHeader__31lEY {
        grid-gap: 0;
        gap: 0;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 25px;
    }

    .Report_aggregatedChartTitle__-EbNT {
        margin: 0;
        margin-bottom: 15px;
    }

    .Report_col2__2ZHcw, .Report_col2WithTitle__3CYTo {
        flex-wrap: wrap;
        grid-gap: 40px;
        gap: 40px;
        margin-bottom: 40px;
    }

    .Report_col1__2xkXf {
        margin-bottom: 40px;
    }

    .Report_col2__2ZHcw > div,
    .Report_col2WithTitle__3CYTo > div {
        width: 100%;
    }
}
.Header_wrapper__1n26p {
    display: flex;
    flex-direction: column;
}

.Header_firstRow__13jyl, .Header_secondRow__25c2z {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 37px;
}

.Header_pageControls__3rp3c {
    display: flex;
    justify-content: space-between;
    grid-gap: 12px;
    gap: 12px;
}

.Header_pageTitle__2myXh {
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 20px;
    line-height: 25.2px;
}

@media (max-width: 767px) {
    .Header_firstRow__13jyl, .Header_secondRow__25c2z {
        flex-wrap: wrap;
        grid-gap: 12px;
        gap: 12px;
    }

    .Header_pageBreadcrumb__1C0JG {
        width: 100%;
    }

    .Header_pageControls__3rp3c {
        width: 100%;
        flex-wrap: wrap;
    }

    .Header_pageControls__3rp3c > div {
        width: 100%
    }
}
.BasicChart_wrapper__3TU7J {
    /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05); */
    border-radius: 8px;
    background: white;
}

.BasicChart_title__1NhFE {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
}

.BasicChart_plotHeader__1lhr0, .BasicChart_plotFooter__U3ZCY {
    display: flex;
    justify-content: space-between;
    padding: 30px 30px 15px;
}

.BasicChart_plotFooter__U3ZCY {
    display: flex;
    justify-content: center;
    padding: 10px 30px 10px;
}

.BasicChart_plotFilters__u4jte {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    padding: 0 30px 0;
    min-height: 38px;
}

.BasicChart_legend__3SjDJ {
    display: flex;
    justify-content: space-between;
    grid-gap: 30px;
    gap: 30px;
}

.BasicChart_legendItem__3SEqY {
    display: flex;
    justify-content: space-between;
    grid-gap: 13px;
    gap: 13px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: #11111BE6;
    align-items: center;
}

.BasicChart_legendItemColor__2sZS2 {
    width: 25px;
    height: 25px;
    display: block;
}

.BasicChart_lineLegendItemColor__9x4TS {
    width: 25px;
    height: 2px;
    display: block;
    background: repeating-linear-gradient(to right,#03022933 0,#03022933 5px,transparent 5px,transparent 7px)
}

@media (max-width: 767px) {
    .BasicChart_plotHeader__1lhr0 {
        flex-direction: column;
        grid-gap: 20px;
        gap: 20px;
    }

    .BasicChart_legendItemColor__2sZS2 {
        width: 15px;
        height: 15px;
        min-width: 15px;
    }

    .BasicChart_legendItem__3SEqY {
        font-size: 12px;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
    }

    .BasicChart_legend__3SjDJ {
        grid-gap: inherit;
        gap: inherit;
        width: 100%;
    }
}

.Heatmap_title__3OiuM {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 15px;
    margin-left: 30px;
    margin-top: 30px;
}

.Heatmap_subTitle__2RgOQ {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 15px;
    margin-left: 44px;
    margin-top: 30px;
}

@media (max-width: 767px) {
    .Heatmap_subTitle__2RgOQ {
        display: block;
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 15px;
        margin-left: 44px;
        margin-top: 30px;
    }
}

.Performance_featuresRow__2mODA {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 22px;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
    border-radius: 13px;
}

.Performance_feature__1yjTN {
    width: calc(100% / 4);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-bottom: 16px;
    padding-top: 16px;
}

.Performance_featureContent__2_pgC {
    display: flex;
    width: 100%;
    padding-left: 30%;
}

.Performance_featureImg__1OGwH {
    display: flex;
    align-items: center;
    margin-right: 14px;
}

.Performance_featureDetail__2KU-B {
    display: flex;
    flex-direction: column;
}

.Performance_featureLabel__2EMkG {
    font-size: 15px;
    color: #8E8E93;
    line-height: 20px;
}

.Performance_featureValue__3DuMW {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
}

@media (max-width: 767px) {
    .Performance_wrapper__1wnDI {
        padding: 25px 25px 0;
    }

    .Performance_feature__1yjTN {
        width: 100%;
    }

    .Performance_featureContent__2_pgC {
        padding-left: 20%;
    }

    .Performance_featuresRow__2mODA {
        margin-bottom: 40px;
    }
}
.Table_wrapper__3A0yB {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    overflow: hidden;
    background: white;
    box-sizing: border-box;
}

.Table_title__3H7-n {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
}

.Table_table__34asJ {
    width: 100%;
    border-collapse: collapse;
}

.Table_tableWrapper__XfS0L {
    box-sizing: border-box;
    width: 100%;
    overflow-y: auto;
    padding-top: 21px;
    max-height: 473px;
    overflow-y: auto !important;
    padding: 0 30px;
}

.Table_table__34asJ tr th {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #24292E;
    padding-bottom: 23px;
    text-transform: capitalize;
}

.Table_table__34asJ tr td {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #24292E;
    padding-bottom: 15px;
    text-transform: capitalize;
}

.Table_tableHeader__3j3NE {
    display: flex;
    justify-content: space-between;
    padding: 30px 30px 15px;
}

.Table_tableFilters__s4nmi {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    padding: 0 30px 45px;
}

.Table_tableData__3P0DR {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    max-width: 100%;
}

.Table_tableWithTotal__189wh tr:last-child td {
    padding-top: 15px;
    border-top: 1px solid black;
}

@media (max-width: 767px) {
    .Table_table__34asJ {
        padding: 0 15px;
    }

    .Table_table__34asJ tr td {
        font-size: 13px;
    }
}
.Leaderboard_title__3d5v6 {
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    color: #11111B;
    opacity: 0.7;
    margin-bottom: 24px;
    display: block;
}

.Leaderboard_featuresRow__O4WAT {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 22px;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
    border-radius: 13px;
}

.Leaderboard_feature__2raXu {
    width: calc(100% / 3);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-bottom: 16px;
    padding-top: 16px;
}

.Leaderboard_featureContent__2CUIV {
    display: flex;
    width: 100%;
    padding-left: 30%;
}

.Leaderboard_featureImg__8PAhf {
    display: flex;
    align-items: center;
    margin-right: 14px;
}

.Leaderboard_featureDetail__2dy5T {
    display: flex;
    flex-direction: column;
}

.Leaderboard_featureLabel__2KBPd {
    font-size: 15px;
    color: #8E8E93;
    line-height: 20px;
}

.Leaderboard_featureValue__2KdFv {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
}

.Leaderboard_detailTitle__moNAb {
    display: block;
    margin-bottom: 16px;
    margin-left: 16px;
    color: #11111Bb3;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px
}

.Leaderboard_insightsTitle__2ubsG {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.15px;
    color: #11111B;
    margin-bottom: 29px;
    display: block;
}

.Leaderboard_specialTitles__3bj5S {
    display: flex;
    grid-gap: 25px;
    gap: 25px
}

.Leaderboard_specialTitles__3bj5S span {
    width: 50%;    
}

.Leaderboard_companySelector__3fkAz {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Leaderboard_companyButton__1mas7 {
    border: 0.5px solid rgba(0,0,0,.1);
    box-shadow: 0 3px 6px rgb(0 0 0 / 8%);
    background-color: transparent;
    border-radius: 8px;
    font-size: 15px;
    width: 300px;
    padding: 7px 0;
    margin-bottom: 10px;
}

.Leaderboard_companyButton__1mas7:hover {
    color: white;
    background-color: #2864C8;
    border-color: #2864C8;
}
.Admin_wrapper__25Lci {
    background: white;
    text-align: left;
    max-width: 1140px;
    margin: auto;
    padding: 20px 0 220px;
}

.Admin_pageHeader__1-VPe {
    margin-bottom: 37px;
}

.Admin_pageTitle__3cTnL {
    margin-top: 10px;
    text-transform: capitalize;
    margin-bottom: 0;
}

.Admin_companySelector__3iEC7 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Admin_companyButton__300Ie {
    border: 0.5px solid rgba(0,0,0,.1);
    box-shadow: 0 3px 6px rgb(0 0 0 / 8%);
    background-color: transparent;
    border-radius: 8px;
    font-size: 15px;
    width: 300px;
    padding: 7px 0;
    margin-bottom: 10px;
}

.Admin_companyButton__300Ie:hover {
    color: white;
    background-color: #2864C8;
    border-color: #2864C8;
}



@media (max-width: 767px) {
    .Admin_wrapper__25Lci {
        padding: 25px 25px 0;
    }
}
.UsersTable_usersTable__2BJ3K {
    width: 100%;
    position: relative;
}

.UsersTable_usersTableRow__36d0e, .UsersTable_usersTableHeader__2jXsi {
    display: flex;
}

.UsersTable_usersTableRow__36d0e {
    padding-bottom: 21px;
    padding-top: 21px;
    border-bottom: 1px solid #69698510;
}

.UsersTable_usersTableHeader__2jXsi {
    font-weight: 500;
}

.UsersTable_usersTableCel__2TBpo, .UsersTable_usersTableHeader__2jXsi {
    width: 30%;
}

.UsersTable_usersTableCel__2TBpo.UsersTable_right__32SJC, .UsersTable_usersTableHeader__2jXsi.UsersTable_right__32SJC {
    width: 10%;
    text-align: right;
    position: relative;
}

.UsersTable_buttonsWrapper__1vuHu {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 30px 5px rgb(0 0 0 / 15%);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    z-index: 4;
    right: 0;
}

.UsersTable_buttonsWrapper__1vuHu button {
    text-align: left;
    background: white;
    border: none;
    cursor: pointer;
    width: 170px;
    padding: 10px 15px;
}

.UsersTable_buttonsWrapper__1vuHu button:hover {
    color: white;
    background: #2864C8;
}

.UsersTable_toggleButton__1eLj5 {
    font-weight: bolder;
    border: none;
    background: none;
    cursor: pointer;
}

.UsersTable_pagination__3Id6O {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
}

.UsersTable_paginationButtons__2WUs0 {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    padding: 7px 6.7px;
}

.UsersTable_paginationButton__QegNE {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
}

.UsersTable_paginationButton__QegNE.UsersTable_disabled__2Uvt6 {
    cursor: not-allowed;
    pointer-events: none !important; 
}

.UsersTable_paginationButton__QegNE.UsersTable_disabled__2Uvt6 svg path {
    fill: rgba(0, 0, 0, 0.25);
}


@media (max-width: 767px) {
    .UsersTable_usersTableRow__36d0e .UsersTable_usersTableHeader__2jXsi:nth-child(3),
    .UsersTable_usersTableRow__36d0e .UsersTable_usersTableHeader__2jXsi:nth-child(2),
    .UsersTable_usersTableRow__36d0e .UsersTable_usersTableCel__2TBpo:nth-child(3),
    .UsersTable_usersTableRow__36d0e .UsersTable_usersTableCel__2TBpo:nth-child(2) {
        display: none;
    }

    .UsersTable_usersTableRow__36d0e {
        justify-content: space-between;
    }
}
.UserEditForm_wrapper__3z17X {
    position: relative;
}

.UserEditForm_title__3rR_k {
    font-weight: bold;
}

.UserEditForm_dialogHeader__3ikqx {
    padding: 25px;
}

.UserEditForm_dialogTitle__2ZnKT {
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.UserEditForm_dialogBody__1e5C- {
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.UserEditForm_dialogBody__1e5C- p {
    margin-bottom: 0;
    margin-top: 20px;
}

.UserEditForm_dialogFooter__3Qvhc {
    border-top: 1px solid #11111b47;
    display: flex;
    justify-content: flex-end;
    padding: 25px;
    grid-gap: 25px;
    gap: 25px;
}

.UserEditForm_dialogFooter__3Qvhc button {
    width: 121px;
    background: #2864C8;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: white;
    box-shadow: none;
    border: none;
    cursor: pointer;
    padding: 8px 0;
}

.UserEditForm_dialogFooter__3Qvhc button:first-child {
    border: 1px solid rgba(86, 103, 137, 0.2);;
    background: white;
    color: #84868A; 
}

.UserEditForm_defaultVenue__3OneZ {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    margin-top: 20px;
}

.UserEditForm_defaultVenuesWrapper__2pCuR {
    max-height: 300px;
    overflow-y: scroll;
    padding-right: 10px;
}

.UserEditForm_deleteButton__3H-Ui {
    background: none;
    border: none;
    cursor: pointer;
}

.UserDetailModal_dialogHeader__2clD1 {
    padding: 25px;
}

.UserDetailModal_dialogTitle__2f6_f {
    font-weight: bold;
}

.UserDetailModal_dialogBody__1TPHK {
    display: flex;
    flex-direction: column;
    padding: 25px;
    text-align: left;
}

.UserDetailModal_dialogBody__1TPHK p {
    margin-bottom: 0;
    margin-top: 20px;
}

.UserDetailModal_dialogFooter__3-Gka {
    border-top: 1px solid #11111b47;
    display: flex;
    justify-content: flex-end;
    padding: 25px;
    grid-gap: 25px;
    gap: 25px;
}

.UserDetailModal_dialogFooter__3-Gka button {
    width: 120px;
    background: #2864C8;
    border-radius: 77px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: white;
    box-shadow: none;
    border: none;
    cursor: pointer;
    padding: 14px 0;
}

.UserForm_wrapper__36VU4 {
    margin-bottom: 94px;
}

.UserForm_userFormInputs__1ipEq {
    display: flex;
    grid-gap: 12px;
    gap: 12px;
}

.UserForm_userFormCel__2Va8D {
    display: flex;
}

.UserForm_userFormInput__3OCms {
    border-radius: 4px;
    border: 1px solid #E1E3E6;
    padding: 6px 15px;
    line-height: 24px;
    box-sizing: border-box;
    width: 197px;
    font-size: 15px;
}

.UserForm_userFormSubmit__1JMS4 {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.UserForm_submitButton__38E3U {
    float: right;
    display: flex;
    padding: 8px 15px;
    cursor: pointer;
    background-color: #2864C8;
    border-radius: 8px;
    color: white;
    opacity: 1;
    border: None;
    align-items: center;
    width: 103px;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
}

@media (max-width: 767px) {
    .UserForm_userFormInputs__1ipEq {
        flex-direction: column;
    }

    .UserForm_userFormCel__2Va8D {
        justify-content: center;
    }

    .UserForm_userFormCel__2Va8D input {
        width: 100%;
    }

    .UserForm_userFormCel__2Va8D > div {
        width: 100%;
    }
}
.VariableAdministration_wrapper__3HQQs {
    display: flex;
    justify-content: space-between;
}

.VariableAdministration_qualityDefinitions__3GxBL, .VariableAdministration_shiftRange__18jis {
    width: calc((100% - 52px)/ 2);
}

.VariableAdministration_qualityDefinitionsRow__2pyko, .VariableAdministration_shiftRangeRow__1gnZ2 {
    display: flex;
}

.VariableAdministration_qualityDefinitionsRow__2pyko, .VariableAdministration_shiftRangeRow__1gnZ2 {
    padding-bottom: 36px;
}

.VariableAdministration_qualityDefinitionsRow__2pyko:nth-child(2) span, .VariableAdministration_shiftRangeRow__1gnZ2:nth-child(2) span {
    font-weight: 600;
}

.VariableAdministration_qualityDefinitions__3GxBL span, .VariableAdministration_shiftRangeRow__1gnZ2 span {
    width: 30%;
}

.VariableAdministration_qualityDefinitions__3GxBL span:last-child, .VariableAdministration_shiftRangeRow__1gnZ2 span:last-child {
    width: 10%;
    text-align: right;
}

.VariableAdministration_tableHeader__2uOmJ {
    display: flex;
    justify-content: space-between;
    margin-bottom: 42px;
}

.VariableAdministration_qualityDefinitions__3GxBL h4, .VariableAdministration_shiftRange__18jis h4 {
    margin: 0;
}

.VariableAdministration_tableHeader__2uOmJ button {
    border: none;
    background: none;
    cursor: pointer;
    color: #2864C8;
    font-weight: bold;
}

.VariableAdministration_qualityDefinitionsCel__TPNE4, .VariableAdministration_shiftRangeCel__33aOF {
    height: 27px;
}

.VariableAdministration_qualityDefinitionsCel__TPNE4 input {
    border: 2px solid #2864C8;
    border-radius: 4px;
    width: 40px;
    font-size: 16px;
    font-family: 'Sora';
}

@media (max-width: 767px) {
    .VariableAdministration_wrapper__3HQQs {
        display: flex;
        flex-direction: column;
    }

    .VariableAdministration_qualityDefinitions__3GxBL, .VariableAdministration_shiftRange__18jis {
        width: 100%;
        margin-bottom: 25px;
    }
}
.Home_wrapper__tKwzO {
    background: white;
    text-align: left;
    max-width: 1140px;
    margin: auto;
    padding: 20px 0 220px;
}

.Home_summary__30tQn h4 {
    margin: 0;
    margin-bottom: 34px;
    opacity: 0.7;
}

.Home_featuresRow__3t6L1 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 22px;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
    border-radius: 13px;
}

.Home_feature__11Nom {
    width: calc(100% / 3);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-bottom: 16px;
    padding-top: 16px;
}

.Home_featureContent__tU3dR {
    display: flex;
    width: 100%;
    padding-left: 30%;
}

.Home_featureImg__1DEf9 {
    display: flex;
    align-items: center;
    margin-right: 14px;
}

.Home_featureDetail__10S_r {
    display: flex;
    flex-direction: column;
}

.Home_featureLabel__cXTGy {
    font-size: 15px;
    color: #8E8E93;
    line-height: 20px;
}

.Home_featureValue__3LLzD {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
}

@media (max-width: 767px) {
    .Home_wrapper__tKwzO {
        padding: 25px 40px 0 10px;
    }

    .Home_feature__11Nom {
        width: 100%;
    }

    .Home_featureContent__tU3dR {
        padding-left: 20%;
    }
}
.Login_background__3TxZ9 {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 100px;
    background: #F7F7FA;
}

.Login_wrapper__3NQO2 {
    display: flex;
    flex-direction: column;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    background: white;
    overflow: hidden;
}

.Login_header__jUkq5 {
    background: #11111B;
    padding: 15px 16px;
}
.Login_body__2LF1f {
    padding: 20px;
}

.Login_body__2LF1f form{
    display: flex;
    flex-direction: column;
}

.Login_body__2LF1f form label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
}

.Login_body__2LF1f form label input {
    font-family: 'Sora', sans-serif;
    margin-top: 5px;
    margin-bottom: 15px;
    background: white;
    box-shadow: none;
    border: 1px solid #E1E3E6;
    font-size: 17px;
    font-weight: 200;
    width: 350px;
}

.Login_submit__3SAhI {
    width: 100%;
    background: #2864C8;
    border-radius: 77px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: white;
    box-shadow: none;
    border: none;
    cursor: pointer;
    padding: 10px 0;
    margin-top: 20px;
}
.Navbar_wrapper__UtDcR {
    width: 100%;
    box-sizing: border-box;
    background: #11111B;
    display: flex;
    justify-content: space-between;
    padding: 0 50px 0;
    position: -webkit-sticky;
    position: sticky;
    z-index: 100;
    top: 0;
}

.Navbar_mobile__39BWV {
    display: none;
}

.Navbar_desktop__2jmTY {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.Navbar_leftCol__S5irt {
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.Navbar_rightCol__17VQ- {
    display: flex;
}

.Navbar_leftCol__S5irt > a {
    padding: 0 39px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: #FFFFFF64;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}

.Navbar_leftCol__S5irt > a:nth-child(2n) {
    color: white;
}

.Navbar_logo__2l4jJ {
    padding: 11px 45px 11px 0;
}

.Navbar_logo__2l4jJ, .Navbar_logo__2l4jJ img {
    height: 26px;
}

.Navbar_rightCol__17VQ- span:last-child {
    padding-right: 0;
    border-right: 0;
}

.Navbar_wrapper__UtDcR a {
    color: white;
    text-decoration: none;
}

.Navbar_wrapper__UtDcR a:hover {
    text-decoration: underline;
}

.Navbar_franchise__1F1uC {
    padding: 17px 32px;
    border-right: 1px solid #FFFFFF32;
    border-left: 1px solid #FFFFFF32;
}

.Navbar_franchise__1F1uC, .Navbar_franchise__1F1uC img {
    width: 83px;
    height: 36px;
}

.Navbar_adminButton__bar0v, .Navbar_userButton__29N3c {
    background: none;
    border: none;
    position: relative;
}

.Navbar_adminButton__bar0v {
    margin-right: 26px;
}

.Navbar_reportMenuButton__3FYK1 {
    padding: 0 39px;
    margin-right: 0;
}

.Navbar_reportMenuButton__3FYK1 .Navbar_buttonArt__1q_DB span{
    color: white;
    font-size: 11px;
    line-height: 18px;
    font-weight: 600;
    text-transform: uppercase;
}

.Navbar_buttonArt__1q_DB {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Navbar_userButton__29N3c span {
    background: #2864C8;
    color: white;
    border-radius: 50px;
    width: 32px;
    height: 32px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}

.Navbar_miniMenu__1Z-_F {
    display: flex;
    flex-direction: column;
    background: white;
    align-items: flex-start;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 0px 30px 5px rgb(0 0 0 / 30%);
}

.Navbar_miniMenuWrapper__2w4bX {
    position: absolute;
    right: 0;
    width: 200px;
}

.Navbar_miniMenu__1Z-_F a {
    box-sizing: border-box;
    width: 100%;
    color: #11111B;
    text-decoration: none;
    text-align: left;
    padding: 11px 8px;
    font-size: 14px;
}

.Navbar_miniMenu__1Z-_F a:hover {
    background: #2864C8;
    color: white;
    text-decoration: none;
}

@media (max-width: 767px) {
    .Navbar_wrapper__UtDcR {
        padding: 0;
    }

    .Navbar_desktop__2jmTY {
        display: none;
    }

    .Navbar_mobile__39BWV {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
    }

    .Navbar_logo__2l4jJ {
        padding: 21px 0;
        border-right: none;
    }

    .Navbar_burger__138rT {
        position: absolute;
        top: 27px;
        height: 14px;
        left: 20px;
    }

    .Navbar_burger__138rT button {
        border: none;
        background: none;
    }

    .Navbar_burgerMenu__2YhjS {
        background: #11111B;
        width: 100%;
        height: 100vh;
    }

    .Navbar_burgerMenu__2YhjS ul{
        color: white;
        padding: 0;
        list-style: none;
    }

    .Navbar_burgerMenu__2YhjS ul li {
        margin-bottom: 15px;
    }
}
.Management_wrapper__3nPKQ {
    background: white;
    text-align: left;
    max-width: 1140px;
    margin: auto;
    padding: 20px 0 220px;
}

.Management_featuresBox__21pha, .Management_featuresRow__2mxLn {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 22px;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
    border-radius: 13px;
}

.Management_featuresBox__21pha {
    width: calc((100% / 6) * 5);
    margin-bottom: 0;
}

.Management_specialtyName__6vDIG {
    width: calc((100% / 6) * 1);
}

.Management_specialtyFeaturesRow__1KiGf {
    display: flex;
    margin-bottom: 22px;
}

.Management_feature__2o0sI {
    width: calc(100% / 4);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-bottom: 16px;
    padding-top: 16px;
}

.Management_specialtyFeature__1OaJB {
    width: calc(100% / 5);
}

.Management_specialtyFeatureNoPortionControl__2GrWj {
    width: calc(100% / 4);
}

.Management_featureContent__1A2v9 {
    display: flex;
    width: 100%;
    padding-left: 30%;
}

.Management_featureImg__2f20Z {
    display: flex;
    align-items: center;
    margin-right: 14px;
}

.Management_featureDetail__3aqow {
    display: flex;
    flex-direction: column;
}

.Management_featureLabel__1RPiO {
    font-size: 15px;
    color: #8E8E93;
    line-height: 20px;
}

.Management_featureValue__1vBzm {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
}

.Management_specialtySummary__8timj {
    padding: 30px 30px;
    position: relative;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
    border-radius: 13px;
}

.Management_specialtySummaryTitle__2twy3 {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 15px;
}

.Management_companySelector__2wYJC {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Management_companyButton__3x_iz {
    border: 0.5px solid rgba(0,0,0,.1);
    box-shadow: 0 3px 6px rgb(0 0 0 / 8%);
    background-color: transparent;
    border-radius: 8px;
    font-size: 15px;
    width: 300px;
    padding: 7px 0;
    margin-bottom: 10px;
}

.Management_companyButton__3x_iz:hover {
    color: white;
    background-color: #2864C8;
    border-color: #2864C8;
}

@media (max-width: 767px) {
    .Management_wrapper__3nPKQ {
        padding: 25px 40px 0 10px;
    }

    .Management_feature__2o0sI {
        width: 100%;
    }

    .Management_featureContent__1A2v9 {
        padding-left: 20%;
    }

    .Management_featuresRow__2mxLn {
        margin-bottom: 40px;
    }

    .Management_specialtyFeaturesRow__1KiGf {
        flex-direction: column;
    }

    .Management_featuresBox__21pha {
        width: 100%;
        margin-bottom: 0;
    }
}
.PizzaTvWeekExplorer_wrapper__2wwyT {
    background: white;
    text-align: left;
    max-width: 1140px;
    margin: auto;
    padding: 50px 0 220px;
}

.PizzaTvWeekExplorer_venuesTable__vfEpr td,
.PizzaTvWeekExplorer_venuesTable__vfEpr th {
    padding: 10px;
}

.PizzaTvWeekExplorer_venuesTable__vfEpr th {
    text-align: center;
}

.PizzaTvWeekExplorer_stateButton__3ey0j {
    border: 1px solid #bfbfbf;
    border-radius: 0;
    color: white;
    padding: 5px 10px;
    width: 200px;
}

.PizzaTvWeekExplorer_blockedCheck__od3Ce {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    font-size: 13px;
}

.PizzaTvWeekExplorer_sendPDFButton__2iMsA {
    border: 1px solid #bfbfbf;
    border-radius: 0;
    background-color: #00b050;
    color: #FFFFFF;
    padding: 5px 10px;
    width: 300px;
}
.PizzaValidation_wrapper__3qE5K {
    background: white;
    text-align: left;
    width: 1700px;
    margin: auto;
    padding: 50px 0 220px;
}

.PizzaValidation_pageContent__3kuXr {
    position: relative;
}

.PizzaValidation_separator__2aZA1 {
    border-top: 1px solid #bfbfbf;
    width: 100%;
}
.PizzaValidationPart1_grid__1OsIa {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 75%;
    padding: 20px 0;
    box-sizing: border-box;
    grid-gap: 20px;
    gap: 20px;
    padding-bottom: 0;
}

.PizzaValidationPart1_card__fUvpr {
    padding: 5px;
    border: 1px solid #E1E3E6;
    box-sizing: border-box;
    width: calc((100% - (20px * 2)) / 3);
    cursor: pointer;
    position: relative;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    background-color: lightgrey;
}

.PizzaValidationPart1_pizzaImg__3isLP {
    width: 50%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.PizzaValidationPart1_information__2i26a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.PizzaValidationPart1_information__2i26a span {
    font-weight: bold;
    font-size: 13px;
}

.PizzaValidationPart1_controls__1P6yg {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.PizzaValidationPart1_checkboxShowReport__3kTi-,
.PizzaValidationPart1_checkboxStuffedCrust__3O7MM,
.PizzaValidationPart1_checkboxFeatured__2oYJZ {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.PizzaValidationPart1_checkboxShowReport__3kTi- label,
.PizzaValidationPart1_checkboxStuffedCrust__3O7MM label,
.PizzaValidationPart1_checkboxFeatured__2oYJZ label {
    font-size: 13px;
    font-weight: 400;
}

.PizzaValidationPart1_separator__3W6td {
    border-top: 1px solid #bfbfbf;
    width: 100%;
}

.PizzaValidationPart1_checkboxFeatured__2oYJZ {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.PizzaValidationPart1_checkboxFeatured__2oYJZ input {
    background-color: white;
}

.PizzaValidationPart1_checkboxFeatured__2oYJZ label {
    color: white;
    font-weight: bold;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.PizzaValidationPart1_radioStandard__2_oDR {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.PizzaValidationPart1_radioStandard__2_oDR label {
    font-size: 18px;
    font-weight: bolder;
}

.PizzaValidationPart1_standardCheckbox__1UQnn:checked {
    background-color: #70ad47 !important;
    border-color: #70ad47 !important;
}

.PizzaValidationPart1_notToStandardCheckbox__2pSV1:checked {
    background-color: red !important;
    border-color: red !important;
}

.PizzaValidationPart1_reportTitle__3gzJD {
    display: block;
    margin-bottom: 25px;
}


.PizzaValidationPart1_inspectedPictureWrapper__p9onn {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    display: flex;
}

.PizzaValidationPart1_inspectedPicture__2hSdR {
    width: 800px;
}

.PizzaValidationPart1_closeInspectedPictureButton__1OPOn {
    position: absolute;
    top: 0;
    right: 0;
    background: white;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.Summary_wrapper__TJmi- {
    display: flex;
    flex-direction: column;
    border: 1px solid #a5a5a5;
    padding: 10px 40px;
    border-radius: 20px;
    align-items: center;
    width: 240px;
}

.Summary_title__3scCm {
    font-weight: bold;
    margin-bottom: 15px;
}

.Summary_row__3CHIE {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    width: 100%;
    margin-top: 5px;
}

.Summary_rowSeparator__1pz95 {
    border-bottom: 1px solid black;
}

.Summary_subtitle__3ZHET {
    font-weight: bold;
    margin-bottom: 13px;
    margin-top: 15px;
}

.Summary_condition__3zwSR {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
}

.Summary_buttons__1Ftnm {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Summary_button__3ZOfA {
    width: 45%;
    padding: 5px 0;
}

.Summary_separator__1362k {
    border-top: 1px solid #bfbfbf;
    width: 100%;
}
.WeekShift_wrapper__3R1ut {
    width: 100%;
}

.WeekShift_wrapper__3R1ut table{
    width: 100%;
}

.WeekShift_wrapper__3R1ut table td{
    border-radius: 50%;
    border: 1px solid #4472c4;
}

.WeekShift_wrapper__3R1ut table td,
.WeekShift_wrapper__3R1ut table th {
    text-align: center;
    font-size: 13px;
    width: 17px;
    height: 17px;
    box-sizing: border-box;
}
.SmartChange_cards__3uOlK {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 1%;
    justify-content: flex-start;
    grid-gap: 10px;
    gap: 10px;
}

.SmartChange_card__1r-zE {
    width: calc((100% - (10px * 4))/ 5);
    display: flex;
    flex-direction: column;
    position: relative;
}

.SmartChange_card__1r-zE img {
    width: 100%;
}

.SmartChange_cardInfo__WfeDX {
    width: 85%;
    display: flex;
    box-sizing: border-box;
    position: absolute;
    bottom: 15px;
    left: 0;
    background-image: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));
}

.SmartChange_cardLegend__2bAJE {
    width: 100%;
    font-size: 25px;
    padding-left: 2%;
}

.SmartChange_cardIcon__12vXS {
    width: 40px !important;
    position: absolute;
    top: 50%;
    right: -20px;
    margin-top: -20px;
}

.SmartChange_cardID__2Y_qx {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    background-color: #ffffffb3;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 25px;
    padding: 5px;
}
.StepSelector_steps__3S5Lr {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    margin-left: -87.5px;
    left: 50%;
}

.StepSelector_step__1Sp-M {
    border-radius: 50%;
    border: 1px solid#4472c4;
    font-size: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
}

.StepSelector_stepSeparator__2c7KR {
    border: 1px solid#4472c4;
    width: 50px;
    height: 3px;
    box-sizing: border-box;
}

.StepSelector_activeStep__BSSnO {
    background-color: #4472c4;
    color: white;
    font-weight: bold;
}
.PizzaValidationPart3_reportPage__G8IGr {
    padding-top: 3.7%;
    padding-left: 9%;
    padding-right: 9%;
    padding-bottom: 3.7%;
    height: calc(1700px * 1.5);
    min-height: calc(100% * 1.5);
    max-height: calc(100% * 1.5);
    border: 1px solid lightgrey;
    box-sizing: border-box;
    position: relative;
}

.PizzaValidationPart3_pageTitle__2kI1K {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 55px;
    margin-top: 4.5%;
}

.PizzaValidationPart3_weeklyPerformanceTable__3RmEZ {
    width: 100%;
    border-collapse: collapse;
    margin-top: 2%;
    margin-bottom: 9.8%;
}

.PizzaValidationPart3_weeklyPerformanceTable__3RmEZ td {
    text-align: center;
    font-size: 50px;
    width: 50%;
}

.PizzaValidationPart3_bordered__2rZTI {
    border: 1px solid black;
    padding: 5% 0;
}

.PizzaValidationPart3_main__3flMZ {
    font-weight: bold;
}

.PizzaValidationPart3_firstCol__3sNNj {
    padding: inherit !important;
}

.PizzaValidationPart3_firstCol__3sNNj div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;
    padding: 0 5%;
}

.PizzaValidationPart3_firstCol__3sNNj img {
    width: 23%;
}

.PizzaValidationPart3_features__2uqkD {
    display: flex;
    justify-content: space-between;
}

.PizzaValidationPart3_feature__3FnC- {
    width: 47%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.PizzaValidationPart3_feature__3FnC- img {
    width: 100%;
}

.PizzaValidationPart3_featureInfo__2mTfq {
    width: 85%;
    display: flex;
    box-sizing: border-box;
    position: absolute;
    bottom: 50px;
    left: 0;
    background-image: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));
}

.PizzaValidationPart3_featureLegend__gjDg7 {
    width: 100%;
    font-size: 50px;
    padding-left: 2%;
}

.PizzaValidationPart3_featureIcon__Qp5LL {
    width: 120px !important;
    position: absolute;
    top: 50%;
    right: -60px;
    margin-top: -60px;
}

.PizzaValidationPart3_kwaliSeal__2Dmbl {
    position: absolute;
    right: 9%;
    bottom: 3.7%;
    width: 15%;
}

.PizzaValidationPart3_coverBrand__1xXps {
    position: absolute;
    width: 1000px;
    left: 50%;
    margin-left: -500px;
    top: 300px
}

.PizzaValidationPart3_coverInfo__28jMl {
    position: absolute;
    top: 700px;
    left: 0;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    border-top: 1px solid #bfbfbf;
    border-right: 1px solid #bfbfbf;
    border-bottom: 1px solid #bfbfbf;
    background-color: #FFFFFF4D;
    width: 88%;
    text-align: right;
    padding: 80px 80px 80px 0;
    box-sizing: border-box;
}

.PizzaValidationPart3_coverInfo__28jMl span {
    display: block;
    color: black;
}

.PizzaValidationPart3_coverInfoTitle__3cG4v {
    font-size: 80px;
    margin-bottom: 80px;
}

.PizzaValidationPart3_coverInfoDescription__2Vg4i {
    font-size: 60px;
    font-weight: 200;
    line-height: 2;
}



.PizzaValidationPart3_issues__2kPp4 {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 4%;
}

.PizzaValidationPart3_issue__FaWLD {
    width: calc((100% - (10px * 2))/ 3);
    display: flex;
    flex-direction: column;
    position: relative;
}

.PizzaValidationPart3_issue__FaWLD img {
    width: 100%;
}

.PizzaValidationPart3_cards__3zMVm {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 1%;
    justify-content: flex-start;
    grid-gap: 10px;
    gap: 10px;
}

.PizzaValidationPart3_shiftedCards__2Krw9 {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    grid-gap: 10px;
    gap: 10px;
}

.PizzaValidationPart3_card__2aB5K {
    width: calc((100% - (10px * 4))/ 5);
    display: flex;
    flex-direction: column;
    position: relative;
}

.PizzaValidationPart3_card__2aB5K img {
    width: 100%;
}

.PizzaValidationPart3_cardInfo__YPzBR {
    width: 85%;
    display: flex;
    box-sizing: border-box;
    position: absolute;
    bottom: 15px;
    left: 0;
    background-image: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));
}

.PizzaValidationPart3_cardLegend__1XIcn {
    width: 100%;
    font-size: 25px;
    padding-left: 2%;
}

.PizzaValidationPart3_cardIcon__1vZC0 {
    width: 40px !important;
    position: absolute;
    top: 50%;
    right: -20px;
    margin-top: -20px;
}

.PizzaValidationPart3_cardID__2E1bS {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    background-color: #ffffffb3;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 30px;
}

.PizzaValidationPart3_tipBlock__luRHt {
    margin-top: 9.8%;
}

.PizzaValidationPart3_tipTitle__21BH5 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.PizzaValidationPart3_tipIcon__2hANM {
    width: 120px;
}

.PizzaValidationPart3_tipText__1zguf {
    font-size: 50px;
}

.PizzaValidationPart3_legend__2yBi3 {
    width: 100%;
    display: flex;
    grid-gap: 50px;
    gap: 50px;
    justify-content: center;
    margin-top: 5%;
}

.PizzaValidationPart3_legendItem__2UzdL {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

.PizzaValidationPart3_legendItem__2UzdL span {
    font-size: 30px;
    display: block;
}

.PizzaValidationPart3_square__2374Y {
    width: 30px;
    height: 30px;
}

.PizzaValidationPart3_circle__22XWL {
    width: 31px;
    height: 31px;
    border-radius: 50%;
}

.PizzaValidationPart3_reportTitle__1Yfew {
    display: block;
    margin-bottom: 25px;
}

.PizzaValidationPart3_flowButtons__2NBSK {
    display: flex;
    justify-content: space-between;
    width: 250px;
    position: fixed;
    bottom: 30px;
    z-index: 2;
    right: 30px;
    background-color: white;
    padding: 8px;
    border-radius: 10px;
}

.PizzaValidationPart3_flowButton__1IgId {
    width: 45%;
    padding: 5px 0;
}

.PizzaValidationPart3_ranking__1JZ9R {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.PizzaValidationPart3_rankingRow__G7TWi {
    font-size: 35px;
    line-height: 35px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    margin-bottom: 40px;
    color: #5e5e59;
}

.PizzaValidationPart3_rankingRow__G7TWi > span:first-child {
    width: 30%;
    padding-right: 20px;
    text-align: left;
    box-sizing: border-box;
}

.PizzaValidationPart3_rankingRow__G7TWi > span:last-child {
    width: 10%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    margin-left: 2%;
}

.PizzaValidationPart3_percentageBar__3VS6I {
    height: 20px;
    display: inline-block;
    border: 3px solid #95a3bb;
    box-sizing: border-box;
}

.PizzaValidationPart3_podiumRow__qWI0P {
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;
    color: #595959;
}

.PizzaValidationPart3_podiumRow__qWI0P .PizzaValidationPart3_percentageBar__3VS6I {
    height: 55px;
}

.PizzaValidationPart3_podiumIcon__1hJWJ {
    height: 55px;
    margin-left: 10px;
}

.PizzaValidationPart3_storeRow__1yxeb .PizzaValidationPart3_rankingBar__3QEz0 {
    background-color: #deebf7; 
}

.PizzaValidationPart3_storeRow__1yxeb .PizzaValidationPart3_percentageBar__3VS6I {
    background-color: #4472c4;
}

.PizzaValidationPart3_chartsWrapper__P_Vdn {
    width: 100%;
    margin-top: 100px;
}

.PizzaValidationPart3_charts__1YAJy {
    display: flex;
    flex-direction: column;
    grid-gap: 50px;
    gap: 50px;
}

.PizzaValidationPart3_specialtyChart__3bVdu {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.PizzaValidationPart3_chartLegend__2ERHg {
    width: 14%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 25px;
    text-align: center;
    align-items: center;
    font-weight: bold;
    grid-gap: 25px;
    gap: 25px;
}

.PizzaValidationPart3_chartLegend__2ERHg img {
    width: 100%;
}

.PizzaValidationPart3_plot__3xBj7 {
    width: 82%;
    border: 1px solid #bfbfbf;
}

.PizzaValidationPart3_disclaimer__HxKx8 {
    font-size: 30px;
    text-align: center;
    display: block;
    color: #7f7f7f;
    margin-top: 1%;
}

.PizzaValidationPart3_rankingBar__3QEz0 {
    width: 60%;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
}

.PizzaValidationPart3_deltaPositive__1NZPx {
    color: #548135;
    background-color: #e1efd8;
    border: 2px solid #548135;
}

.PizzaValidationPart3_deltaNegative__iGKLN {
    color: #c00000;
    background-color: #fbcfd0;
    border: 2px solid #c00000;
}

.PizzaValidationPart3_deltaZero__1Wt-q {
    color: black;
    background-color: white;
    border: 2px solid black;
}

.PizzaValidationPart3_deltaTitle__KJVrF {
    width: 100%;
    text-align: right;
    font-size: 25px;
    margin-bottom: 20px;
}

.PizzaValidationPart3_storesEvolutionTable__2LRKu {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    font-size: 17px;
}

.PizzaValidationPart3_storesEvolutionTable__2LRKu td {
    border: 2px solid #e4e4e4;
    width: calc((100% - 25.5%) / 12);
}

.PizzaValidationPart3_storesEvolutionTable__2LRKu td:first-child {
    width: 25.5%;
}

.PizzaValidationPart3_inspectedPictureWrapper__3jlmO {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    display: flex;
}

.PizzaValidationPart3_inspectedPicture__2ZJfH {
    width: 800px;
}

.PizzaValidationPart3_closeInspectedPictureButton__6JSj8 {
    position: absolute;
    top: 0;
    right: 0;
    background: white;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.OrdinalDate_ordinal__39fBn {
    display: inline !important;
}

.OrdinalDate_ordinal__39fBn sup {
    font-size: 0.7em;
    vertical-align: super;
}
.MonthlyReportPreview_wrapper__1Mjsy {
    background: white;
    text-align: left;
    width: 1700px;
    margin: auto;
    padding: 50px 0 220px;
}

.MonthlyReportPreview_pageContent___-Htx {
    position: relative;
}

.MonthlyReportPreview_reportPage__1O7fz {
    padding-top: 3.7%;
    padding-left: 9%;
    padding-right: 9%;
    padding-bottom: 3.7%;
    height: calc(1700px * 1.5);
    min-height: calc(100% * 1.5);
    max-height: calc(100% * 1.5);
    border: 1px solid lightgrey;
    box-sizing: border-box;
    position: relative;
}

.MonthlyReportPreview_pageTitle__1E9pV {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 55px;
}

.MonthlyReportPreview_weeklyPerformanceTable__2nGy_ {
    width: 100%;
    border-collapse: collapse;
    margin-top: 2%;
    margin-bottom: 9.8%;
}

.MonthlyReportPreview_weeklyPerformanceTable__2nGy_ td {
    text-align: center;
    font-size: 50px;
    width: calc(60% / 3);
}

.MonthlyReportPreview_bordered__2IFlM {
    border: 1px solid black;
    padding: 5% 0;
}

.MonthlyReportPreview_main__3D7iw {
    font-weight: bold;
}

.MonthlyReportPreview_firstCol__2HFBV {
    width: 40% !important;
    padding: inherit !important;
}

.MonthlyReportPreview_firstCol__2HFBV div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;
    padding: 0 5%;
}

.MonthlyReportPreview_firstCol__2HFBV img {
    width: 23%;
}

.MonthlyReportPreview_features__1F1wf {
    display: flex;
    justify-content: space-between;
}

.MonthlyReportPreview_feature__3DS2q {
    width: 47%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.MonthlyReportPreview_feature__3DS2q img {
    width: 100%;
}

.MonthlyReportPreview_featureInfo__1d0Ig {
    width: 85%;
    display: flex;
    box-sizing: border-box;
    position: absolute;
    bottom: 50px;
    left: 0;
    background-image: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));
}

.MonthlyReportPreview_featureLegend__26Haf {
    width: 100%;
    font-size: 50px;
    padding-left: 2%;
}

.MonthlyReportPreview_featureIcon__3zehQ {
    width: 120px !important;
    position: absolute;
    top: 50%;
    right: -60px;
    margin-top: -60px;
}

.MonthlyReportPreview_kwaliSeal__2qzSk {
    position: absolute;
    right: 9%;
    bottom: 3.7%;
    width: 15%;
}

.MonthlyReportPreview_coverBrand__24y6y {
    position: absolute;
    width: 1000px;
    left: 50%;
    margin-left: -500px;
    top: 300px
}

.MonthlyReportPreview_coverInfo__br0bc {
    position: absolute;
    top: 700px;
    left: 0;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    border-top: 1px solid #bfbfbf;
    border-right: 1px solid #bfbfbf;
    border-bottom: 1px solid #bfbfbf;
    background-color: #FFFFFF4D;
    width: 88%;
    text-align: right;
    padding: 80px 80px 80px 0;
    box-sizing: border-box;
}

.MonthlyReportPreview_coverInfo__br0bc span {
    display: block;
    color: black;
}

.MonthlyReportPreview_coverInfoTitle__1mKX5 {
    font-size: 80px;
    margin-bottom: 80px;
}

.MonthlyReportPreview_coverInfoDescription__2btwA {
    font-size: 60px;
    font-weight: 200;
    line-height: 2;
}



.MonthlyReportPreview_issues__15CvN {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 4%;
}

.MonthlyReportPreview_issue__uA7J4 {
    width: calc((100% - (10px * 2))/ 3);
    display: flex;
    flex-direction: column;
    position: relative;
}

.MonthlyReportPreview_issue__uA7J4 img {
    width: 100%;
}




.MonthlyReportPreview_cards__3Gz4B {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 1%;
    justify-content: flex-start;
    grid-gap: 10px;
    gap: 10px;
}

.MonthlyReportPreview_card__3oa41 {
    width: calc((100% - (10px * 4))/ 5);
    display: flex;
    flex-direction: column;
    position: relative;
}

.MonthlyReportPreview_card__3oa41 img {
    width: 100%;
}

.MonthlyReportPreview_cardInfo__2LURI {
    width: 85%;
    display: flex;
    box-sizing: border-box;
    position: absolute;
    bottom: 15px;
    left: 0;
}

.MonthlyReportPreview_cardLegend__17WZe {
    width: 100%;
    font-size: 25px;
    padding-left: 2%;
    min-height: 31.5px;
}

.MonthlyReportPreview_cardIcon__2NuBt {
    width: 40px !important;
    position: absolute;
    top: 50%;
    right: -20px;
    margin-top: -20px;
}


.MonthlyReportPreview_tipBlock__31Pql {
    margin-top: 9.8%;
}

.MonthlyReportPreview_tipTitle__1jwjg {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.MonthlyReportPreview_tipIcon__2iCb3 {
    width: 120px;
}

.MonthlyReportPreview_tipText__1NkFe {
    font-size: 50px;
}

.MonthlyReportPreview_legend__2wD5m {
    width: 100%;
    display: flex;
    grid-gap: 50px;
    gap: 50px;
    justify-content: center;
    margin-top: 5%;
}

.MonthlyReportPreview_legendItem__3KOL1 {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

.MonthlyReportPreview_legendItem__3KOL1 span {
    font-size: 30px;
    display: block;
}

.MonthlyReportPreview_square__RxTtS {
    width: 30px;
    height: 30px;
}

.MonthlyReportPreview_circle__2zNbs {
    width: 31px;
    height: 31px;
    border-radius: 50%;
}

.MonthlyReportPreview_steps__2rGiS {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    margin-left: -87.5px;
    left: 50%;
}

.MonthlyReportPreview_step__31b6K {
    border-radius: 50%;
    border: 1px solid#4472c4;
    font-size: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.MonthlyReportPreview_stepSeparator__3-3SI {
    border: 1px solid#4472c4;
    width: 50px;
    height: 3px;
    box-sizing: border-box;
}

.MonthlyReportPreview_reportTitle__2qUC4 {
    display: block;
    margin-bottom: 25px;
}

.MonthlyReportPreview_flowButtons__2nLCT {
    display: flex;
    justify-content: space-between;
    width: 250px;
    position: fixed;
    bottom: 30px;
    z-index: 2;
    right: 30px;
    background-color: white;
    padding: 8px;
    border-radius: 10px;
}

.MonthlyReportPreview_flowButton__1n1g- {
    width: 45%;
    padding: 5px 0;
}

.MonthlyReportPreview_ranking__2O316 {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.MonthlyReportPreview_rankingRow__12Vqb {
    line-height: 90px;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    font-weight: 500;
    font-size: 25px;
    color: #7f7f7f;
}

.MonthlyReportPreview_percentageBar__3gpRZ {
    height: 55px;
    display: inline-block;
    border: 3px solid #bdbdbd;
    border-left: none;
    box-sizing: border-box;
}

.MonthlyReportPreview_chartsWrapper__3lEtM {
    width: 100%;
    margin-top: 30px;
}

.MonthlyReportPreview_charts__31G4i {
    display: flex;
    flex-direction: column;
    grid-gap: 50px;
    gap: 50px;
}

.MonthlyReportPreview_specialtyChart__1U_MR {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MonthlyReportPreview_chartLegend__3CNIJ {
    width: 14%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 25px;
    text-align: center;
    align-items: center;
    font-weight: bold;
    grid-gap: 25px;
    gap: 25px;
}

.MonthlyReportPreview_chartLegend__3CNIJ img {
    width: 100%;
}

.MonthlyReportPreview_plot__8WJoj {
    width: 82%;
    border: 1px solid #bfbfbf;
}

.MonthlyReportPreview_disclaimer__1HVLb {
    font-size: 30px;
    text-align: center;
    display: block;
    color: #7f7f7f;
    margin-top: 1%;
}

.MonthlyReportPreview_rankingBar__1lDk2 {
    width: 100%;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
}

.MonthlyReportPreview_delta__hVkZi {
    display: block;
    width: 30%;
    line-height: 40px;
    font-weight: 500;
    font-size: 30px;
}

.MonthlyReportPreview_deltaPositive__2Ojlm {
    color: #1ba31b;
}

.MonthlyReportPreview_deltaNegative__sANEj {
    color: #fe0808;
}

.MonthlyReportPreview_deltaZero__3Fl6z {
    color: black;
}

.MonthlyReportPreview_storesEvolutionTable__1YrYN {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    font-size: 17px;
}

.MonthlyReportPreview_storesEvolutionTable__1YrYN td {
    border: 2px solid #e4e4e4;
}

.MonthlyReportPreview_storesEvolutionTable__1YrYN td:first-child {
    width: 25.5%;
}

.MonthlyReportPreview_inspectedPictureWrapper__3RKmv {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    display: flex;
}

.MonthlyReportPreview_inspectedPicture__1Pknj {
    width: 800px;
}

.MonthlyReportPreview_closeInspectedPictureButton__3BFPO {
    position: absolute;
    top: 0;
    right: 0;
    background: white;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.MonthlyReportPreview_resumeTable__3ekM_ {
    width: 100%;
    text-align: center;
    font-size: 30px;
    line-height: 30px;
    border-collapse: collapse;
    margin-top: 70px;
}

.MonthlyReportPreview_resumeTable__3ekM_ td, .MonthlyReportPreview_resumeTable__3ekM_ th {
    border: 2px solid black;
    padding: 17px 0;
}

.MonthlyReportPreview_rankingTable__3md6d {
    width: 100%;
    border-collapse: collapse;
}

.MonthlyReportPreview_rankingTable__3md6d th {
    font-size: 25px;
    text-align: center;
    border-bottom: 3px solid black;
}

.MonthlyReportPreview_layout1__1IBAS {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    margin-bottom: 100px;
}

.MonthlyReportPreview_layout1Title__JjgTY {
    font-size: 40px;
    width: 20%;
    text-align: left;
}

.MonthlyReportPreview_layout1Table__XCVOV {
    border-collapse: collapse;
    font-size: 40px;
    width: 78%
}

.MonthlyReportPreview_layout1Table__XCVOV th {
    background-color: #5b9bd5;
    color: white;
}

.MonthlyReportPreview_layout1Table__XCVOV td, .MonthlyReportPreview_layout1Table__XCVOV th {
    width: 12%;
}

.MonthlyReportPreview_borderedRow__28D5d td {
    border: 1px solid #bdbdbd;
}

.MonthlyReportPreview_layout1legend__2lX2g {
    display: flex;
    justify-content: space-evenly;
    margin-top: 80px;
}

.MonthlyReportPreview_layout1legendItem__1QQPe {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
}

.MonthlyReportPreview_layout1legendText__31_FB {
    font-size: 40px;
}

.MonthlyReportPreview_layout1legendColorIndicator__3TU7p {
    height: 49px;
    width: 190px;
    border: 1px solid #bdbdbd;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: 600;
}

.MonthlyReportPreview_layout1RightCol__2JEbl {
    width: 80%;
    display: flex;
    align-items: center;
}

.MonthlyReportPreview_layout1RightColIcon__VbjAx {
    height: 250px;
    z-index: 2;
}

.MonthlyReportPreview_layout1RightColPercentageBar__2LrPj {
    height: 150px;
    /*background-image: linear-gradient(to right, #ffffff, #f3e6fb, #f4cbeb, #fbaccf, #ff8ea7, #ff8192, #fe767a, #f96c61, #f96c61, #f96c61, #f96c61, #f96c61);*/
    background-color: #f96c61;
    display: flex;
    align-items: center;
    z-index: 1;
}

.MonthlyReportPreview_layout1RightColPercentageBarInnerLabel__Jl4Hk {
    width: 100%;
    text-align: right;
    padding-right: 40px;
    font-weight: 500;
    font-size: 40px;
    color: white;
}

.MonthlyReportPreview_layout1RightColPercentageBarOuterLabel__3wpef {
    font-size: 40px;
    font-weight: 500;
    padding-left: 60px;
}
.PizzaTv_wrapper__17DAE {
    zoom: 100%;
    display: flex;
    --border-transition: 0.3s;
    padding-top: 20px;
    padding-right: 50px;
    padding-bottom: 20px;
    padding-left: 50px;
    box-sizing: border-box;
}

.PizzaTv_mainPanel__kbjfh {
    width: 70%;
    --border-transition: 0.3s;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #DDDDDD;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
    padding-top: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
}

.PizzaTv_title__2LMXm {
    color: #2266c4;
    font-size: 100px;
    font-weight: 700;
    position: relative;
    top: -24px;
}

.PizzaTv_carousels__2Fhf3 {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.PizzaTv_carouselWrapper__2EpxN {
    width: calc((100% - 60px) / 2);
}

.PizzaTv_carouselTitle__1wELl {
    color: #000000;
    font-size: 70px;
    font-weight: 600;
    margin-bottom: 40px;
    display: block;
}

.PizzaTv_carousel__kp98j {
    display: flex;
    overflow: hidden;
    width: 100%;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #DDDDDD;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
    padding: 10px 0;
    box-sizing: border-box;
}

.PizzaTv_card__1ZZJQ {
    min-width: 100%;
    transition: transform 0.5s ease-out;width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 10px;
}

.PizzaTv_card__1ZZJQ img {
    width: 100%;
}

.PizzaTv_cardInfo__2bkKY {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    position: absolute;
    bottom: 100px;
    left: 0;
    background-color: transparent;
}

.PizzaTv_infoGreen__2QdR7 {
    background-image: linear-gradient(193deg, #5D161621 0%, #118603 100%);
}

.PizzaTv_infoRed__3IBWP {
    background-image: linear-gradient(193deg, #5D161621 0%, #FF0000 100%);
}

.PizzaTv_cardLegend__1tRjC {
    width: 100%;
    color: #FFFFFF;
    font-family: "Sora", Sans-serif;
    font-size: 60px;
    text-align: left;
    font-weight: 700;
    padding-top: 20px;
    padding-right: 8px;
    padding-bottom: 20px;
    padding-left: 25px;
}

.PizzaTv_cardIcon__2bETl {
    width: 200px !important;
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -100px;
}

.PizzaTv_cardIconCircle__2O6Zt {
    width: 200px !important;
    height: 200px !important;
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -100px;
    background-color: white;
    border: 2px solid #f1b605;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.PizzaTv_cardIconCircle__2O6Zt img {
    width: 80%;
}

.PizzaTv_tipWrapper__2cAdb {
    display: flex;
    align-items: center;
    margin-top: 70px;
}

.PizzaTv_tipTextWrapper__XecFs {
    margin: 2px 0px 2px -118px;
    padding: 70px 80px 70px 150px;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #BBBBBB;
    border-radius: 0% 0% 0% 0%;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
}

.PizzaTv_tipText__3-JiU {
    color: #000000;
    font-size: 78px;
    font-weight: 600;
}

.PizzaTv_tipIconWrapper__5HolN {
    margin: 0px 0px 0px 0px;
    padding: 50px 50px 50px 50px;
    background-color: #FFFFFF;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #CCCCCC;
    border-radius: 1000% 1000% 1000% 1001%;
    box-shadow: 3px 5px 5px 0px rgb(0 0 0 / 50%);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 300px;
    max-width: 300px;
    height: 300px;
    grid-gap: 25px;
    gap: 25px;
}

.PizzaTv_tipIcon__3aqJH {
    width: 130px;
    border-style: none;
    border-radius: 100% 100% 100% 100%;
    vertical-align: middle;
    display: inline-block;
}

.PizzaTv_rightPanel__2QXpa {
    width: 30%;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #DDDDDD;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    padding-top: 50px;
    justify-content: space-between;
}

.PizzaTv_rightPanelTitle__3ZiP5 {
    color: #000000;
    font-size: 80px;
    font-weight: 600;
    display: block;
}

.PizzaTv_rightPanelTitle2__3lVdF {
    color: #000000;
    font-size: 60px;
    font-weight: 500;
    display: block;
}

.PizzaTv_rightPanelIcon__2Es-U {
    width: 350px;
    z-index: 2;
}

.PizzaTv_rightPanelCardWrapper__zcRti {
    display: flex;
    align-items: center;
}

.PizzaTv_rightPanelCardText__1BQ10 {
    color: #000000;
    font-size: 80px;
    font-weight: 600;
}

.PizzaTv_percentageBar__1gBzG {
    height: 80px;
    margin-left: -40px;
    box-shadow: 10px 10px 5px 0px #999999;
    margin-right: 30px;
}

.PizzaTv_franchiseRanking__39wjN {
    width: 100%;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #DDDDDD;
    box-shadow: 0 -10px 10px -10px rgb(0 0 0 / 50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 1000px;
    padding-top: 50px;
}

.PizzaTv_rankingTable__1S5U7 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    margin-top: 80px;
}

.PizzaTv_rankingRow__Ccr9b {
    color: #000000;
    font-size: 45px;
    line-height: 45px;
    padding: 0 43px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
}

.PizzaTv_rankingNotPrincipal__BQcCk {
    font-size: 35px;
    line-height: 35px;
    color: #000000;
    opacity: 0.3;
}

.PizzaTv_rankingPrincipal__BJMtf {
    border: 3px solid #DDDDDD;
    box-shadow: 10px 10px 5px 0px #999999;
    border-radius: 50px;
    padding: 15px 40px;
    color: #000000;
    font-size: 45px;
    line-height: 45px;
    opacity: 1;
    font-weight: bold;
}

.PizzaTv_rankingFirstCol__2WvTq {
    width: 85%
}

.PizzaTv_rankingFirstCol__2WvTq table {
    width: 100%;
    text-align: left;
}

.PizzaTv_rankingFirstCol__2WvTq table td:first-child{
    width: 12%;
    text-align: center;
}

.PizzaTv_rankingFirstCol__2WvTq table td:nth-child(2){
    padding-left: 10px;
}

.ReportControl_wrapper__2_f2c {
    background: white;
    text-align: left;
    margin: auto;
    padding: 30px 50px 10px;
}

.ReportControl_refreshButton__2YuwA {
    display: block;
    position: absolute;
    top: 78px;
    right: 50px;
    background-color: #a8d08c;
    border: 1px solid #E1E3E6;
    border-radius: 4px;
    color: white;
    padding: 6px 15px;
    line-height: 24px;
}

.ReportControl_sendAllButton__2_zA1 {
    background-color: #a8d08c;
    border: 1px solid #E1E3E6;
    border-radius: 4px;
    color: white;
    padding: 6px 15px;
    width: 100%;
}

.ReportControl_pageContent__2yGaD {
    display: flex;
    font-size: 12px;
    grid-gap: 10px;
    gap: 10px;
}

.ReportControl_companyResume__1KAHR {
    border: 1px solid gray;
    width: calc((100% - 10px) * 0.25);
    padding: 34px 10px 0;
}

.ReportControl_companyResumeTable__2r0Xy {
    border-collapse: collapse;
    width: 100%;
    line-height: 2;
}

.ReportControl_highlightRow__10MO8 > td {
    background-color: #d6e0f2;
}


.ReportControl_companyResumeTable__2r0Xy th,
.ReportControl_companyResumeTable__2r0Xy td {
    text-align: center;
}

.ReportControl_square__23Zl3 {
    border: 1px solid #505050;
    background-color: white;
}

.ReportControl_deltaZero__3SOw4 {
    background-color: #ffffff !important;
}

.ReportControl_deltaNegative__2dNzy {
    background-color: #fe9999 !important;
}

.ReportControl_deltaPositive__2_-hi {
    background-color: #e1eed8 !important;
}

.ReportControl_storeResume__GjxMA {
    width: calc((100% - 10px) * 0.75);
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    max-height: 750px;
    overflow-y: scroll;
}

.ReportControl_firstRow__1kDMK,
.ReportControl_secondRow__1g7sP {
    border: 1px solid gray;
    padding: 20px 10px 20px;
}

.ReportControl_storeResumeTable__2nUQG {
    border-collapse: collapse;
}

.ReportControl_storeResumeTable__2nUQG > tbody > tr > td:nth-child(1) {
    width: 43%;
}

.ReportControl_storeResumeTable__2nUQG > tbody > tr > td:nth-child(2) {
    width: 7%;
}

.ReportControl_storeResumeTable__2nUQG > tbody > tr > td:nth-child(3) {
    width: 25%;
}

.ReportControl_storeResumeTable__2nUQG > tbody > tr > td:nth-child(4) {
    width: 25%;
}

.ReportControl_storeResumeTable__2nUQG > tbody > tr >  th {
    text-align: center;
}

.ReportControl_storeResumeTable__2nUQG > tbody > tr >  td {
    border: 1px solid gray;
    padding: 5px;
}

.ReportControl_pizzaCountTable__1pou9 {
    width: 100%;
    line-height: 2;
}

.ReportControl_pizzaCountTable__1pou9 td {
    border: none;
    text-align: center;
}

.ReportControl_simulatorTable__26q3D,
.ReportControl_stressTestTable__3CwPk {
    width: 100%;
}

.ReportControl_simulatorTable__26q3D th,
.ReportControl_simulatorTable__26q3D td,
.ReportControl_stressTestTable__3CwPk th,
.ReportControl_stressTestTable__3CwPk td {
    border: 1px solid grey;
    text-align: center;
    width: 20%;
}

.ReportControl_simulatorTable__26q3D th:nth-child(2) {
    background-color: #fe0000;
    color: white;
}

.ReportControl_simulatorTable__26q3D th:nth-child(3) {
    background-color: #12b050;
    color: white;
}

.ReportControl_simulatorTable__26q3D td,
.ReportControl_stressTestTable__3CwPk td {
    line-height: 6;
    background-color: #efefef;
}

.ReportControl_simulatorTable__26q3D td input {
    border: none;
    padding: 0;
    width: 100%;
    text-align: center;
    line-height: 5;
}


.ReportControl_features__5e1TH {
    display: flex;
    justify-content: space-between;
}

.ReportControl_feature__1drqE {
    width: 47%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.ReportControl_feature__1drqE img {
    width: 100%;
}

.ReportControl_featureInfo__2UNdW {
    width: 85%;
    display: flex;
    box-sizing: border-box;
    position: absolute;
    bottom: 50px;
    left: 0;
    background-image: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));
}

.ReportControl_featureLegend__1osU8 {
    width: 100%;
    font-size: 50px;
    padding-left: 2%;
}

.ReportControl_featureIcon__Uif4T {
    width: 120px !important;
    position: absolute;
    top: 50%;
    right: -60px;
    margin-top: -60px;
}

.ReportControl_cards__1j3u_ {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 1%;
    justify-content: flex-start;
    grid-gap: 10px;
    gap: 10px;
}

.ReportControl_shiftedCards__1LNWN {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    grid-gap: 10px;
    gap: 10px;
}

.ReportControl_card__taMcv {
    width: calc((100% - (10px * 4))/ 5);
    display: flex;
    flex-direction: column;
    position: relative;
}

.ReportControl_card__taMcv img {
    width: 100%;
}

.ReportControl_cardInfo__2qfqf {
    width: 85%;
    display: flex;
    box-sizing: border-box;
    position: absolute;
    bottom: 15px;
    left: 0;
    background-image: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));
}

.ReportControl_cardLegend__3CqNC {
    width: 100%;
    font-size: 25px;
    padding-left: 2%;
}

.ReportControl_cardIcon__1xLQ4 {
    width: 40px !important;
    position: absolute;
    top: 50%;
    right: -20px;
    margin-top: -20px;
}

.ReportControl_inspectedPictureWrapper__3iiYj {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    display: flex;
}

.ReportControl_inspectedPicture__2u2_S {
    width: 800px;
}

.ReportControl_closeInspectedPictureButton__3aexT {
    position: absolute;
    top: 0;
    right: 0;
    background: white;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.ReportControl_downloadReportButton__zxHML {
    border: none;
    background: none;
    padding: 0;
    font-size: 12px;
}

.ReportControl_storesEvolutionTable__2opA3 {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    font-size: 25px;
}

.ReportControl_storesEvolutionTable__2opA3 td {
    border: 2px solid #e4e4e4;
    width: calc((100% - 20%) / 12);
}

.ReportControl_storesEvolutionTable__2opA3 td:first-child {
    width: 20%;
}

.ReportControl_cardID__2kZKI {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    background-color: #ffffffb3;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 30px;
}
.OperationControlCenter_wrapper__UQoPk {
    background: white;
    text-align: left;
    margin: auto;
    padding: 30px 50px 10px;
}

.OperationControlCenter_pageContent__DYO6r {
    display: flex;
    font-size: 12px;
    grid-gap: 10px;
    gap: 10px;
}

.OperationControlCenter_refreshButton__1vIet {
    display: block;
    position: absolute;
    top: 78px;
    right: 50px;
    background-color: #a8d08c;
    border: 1px solid #E1E3E6;
    border-radius: 4px;
    color: white;
    padding: 6px 15px;
    line-height: 24px;
}
