.wrapper {
    display: flex;
    max-height: 685px;
}

.rightPanel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 40px 23px 31px;
}

.imageWrapper {
    position: relative;
}

.imageWrapper button {
    position: absolute;
}

.image {
    width: 100%;
    max-width: 685px;
}

.tablesWrapper {
    width: 395px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 25px;
    padding: 0 5px 51px 0;
    border-bottom: 1px solid #E1E3E6;
    max-height: 520px;
    overflow-y: auto;
}

.editScoring {
    background: #2864C8;
    border-radius: 77px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: white;
    padding: 11px 0;
    box-shadow: none;
    border: none;
    cursor: pointer;
}

.editScoringIcon {
    margin-right: 9px;
}

.imageButton {
    border-radius: 25px;
    border: 1px solid #E1E3E6;
    background-color: white;
    color: #2864C8;
    padding: 9px;
    height: 21px;
    box-sizing: content-box;
    width: 21px;
    font-size: 19px;
    top: 20px;
    cursor: pointer;
}

.imageButton svg {
    margin: 0;
}

.commentButton {
    right: 75px;
}

.expandButton {
    right: 20px;
}

.mobileScore {
    display: none;
}

@media (max-width: 767px) {
    .wrapper {
        flex-direction: column;
        overflow: visible;
        max-height: none;
    }

    .rightPanel {
        overflow: visible;
        padding: 0 20px 0;
        margin-bottom: 20px;
    }

    .tablesWrapper {
        width: 100%;
        max-height: none;
        padding-bottom: 13px;
        margin-bottom: 20px;
    }

    .mobileScore {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .mobileScore span {
        text-transform: uppercase;
    }
}