.background {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 100px;
    background: #F7F7FA;
}

.wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    background: white;
    overflow: hidden;
}

.header {
    background: #11111B;
    padding: 15px 16px;
}
.body {
    padding: 20px;
}

.body form{
    display: flex;
    flex-direction: column;
}

.body form label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
}

.body form label input {
    font-family: 'Sora', sans-serif;
    margin-top: 5px;
    margin-bottom: 15px;
    background: white;
    box-shadow: none;
    border: 1px solid #E1E3E6;
    font-size: 17px;
    font-weight: 200;
    width: 350px;
}

.submit {
    width: 100%;
    background: #2864C8;
    border-radius: 77px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: white;
    box-shadow: none;
    border: none;
    cursor: pointer;
    padding: 10px 0;
    margin-top: 20px;
}