.information {
    display: flex;
    justify-content: space-between;
}

.name {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
}

.score {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #2864C8;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table tr th {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 10px;
    color: #676767;
    background-color: #EAF2FF;
    width: 12.5%;
    border: 1px solid #D0D0D0;
}

.table tr td {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 20px;
    width: 12.5%;
    border: 1px solid #D0D0D0;
}