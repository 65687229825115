.kwali-calendar,
.kwali-range-calendar {
    width: 100%;
    border: none !important;
    border-radius: 0 !important;
    font-family: 'Sora', sans-serif !important;
}

.kwali-calendar .react-datepicker__month-container {
    width: 224px;
}

.kwali-calendar .react-datepicker__header__dropdown,
.kwali-range-calendar .react-datepicker__header__dropdown {
    display: flex !important;
    justify-content: center !important;
    gap: 25px !important;
    font-size: 15px !important;
    font-style: normal;
    font-weight: normal;
    height: 31px;
    align-items: flex-start;
}

.kwali-calendar .react-datepicker__current-month {
    display: none !important;
}

.kwali-calendar .react-datepicker__header,
.kwali-range-calendar .react-datepicker__header {
    background: white !important;
    border: none !important;
}

.kwali-calendar .react-datepicker__week,
.kwali-calendar .react-datepicker__day-names,
.kwali-range-calendar .react-datepicker__week,
.kwali-range-calendar .react-datepicker__day-names {
    display: flex !important;
    justify-content: space-between !important;
    font-size: 14px;
}

.kwali-calendar .react-datepicker__day-name,
.kwali-range-calendar .react-datepicker__day-names .react-datepicker__day-name{
    font-size: 15px;
    font-weight: 600;
    color: #11111b !important;
}

.kwali-calendar .react-datepicker__week,
.kwali-range-calendar .react-datepicker__week {
    font-size: 14px;
}

.kwali-calendar .react-datepicker__month-read-view--down-arrow,
.kwali-range-calendar .react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
    width: 4px !important;
    height: 4px !important;
    top: 5px !important;
}

.kwali-calendar .react-datepicker__month,
.kwali-range-calendar .react-datepicker__month {
    margin: 0 !important;
    display: flex !important;
    flex-direction: column !important;
}
.kwali-calendar .react-datepicker__day,
.kwali-range-calendar .react-datepicker__day {
    line-height: 1;
    margin: 0;
    margin-bottom: 2px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.kwali-calendar .react-datepicker__year-dropdown,
.kwali-range-calendar .react-datepicker__year-dropdown,
.react-datepicker__month-dropdown {
    background: white !important;
    font-weight:  400 !important;
}

.react-datepicker__month-read-view,
.react-datepicker__year-read-view {
    visibility: inherit !important;
}

.kwali-calendar .react-datepicker__day--keyboard-selected {
    background-color: white !important;
    color: #11111b;
}

.kwali-range-calendar .react-datepicker__day--keyboard-selected {
    background-color: white !important;
    color: #11111b;
}

.kwali-calendar .react-datepicker__day--selected {
    background-color: #2863c8 !important;
    color: white !important;
    border-radius: 50px !important;
}

.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: #2864C8 !important;
    border-radius: 3px !important;
}


.kwali-range-calendar .react-datepicker__day--in-range {
    background-color: rgba(0, 0, 0, 0.05) !important;
    color: #11111b !important;
    border-radius: 0 !important;
}


.kwali-range-calendar .react-datepicker__day:hover,
.kwali-range-calendar .react-datepicker__day--range-start,
.kwali-range-calendar .react-datepicker__day--range-end,
.kwali-calendar .react-datepicker__day--in-range {
    background-color: #2863c8 !important;
    color: white !important;
    border-radius: 50px !important;
}


.dont-show-selection .kwali-range-calendar .react-datepicker__day--in-range {
    background-color: white !important;
    color: #11111b !important;
}

.kwali-calendar .react-datepicker__day--outside-month,
.kwali-range-calendar .react-datepicker__day--outside-month {
    opacity: 0 !important;
    pointer-events: none !important; 
}

.kwali-range-calendar .react-datepicker__month-container .react-datepicker__current-month {
    display: block !important;
    height: 31px !important;
    font-weight: normal;
    font-size: 17px;
}

.kwali-range-calendar .react-datepicker__month-container .react-datepicker__header__dropdown--scroll {
    position: absolute;
    top: -25px;
    left: 179px;
    z-index: 2;
}

.kwali-range-calendar .react-datepicker__month-dropdown {
    width: 125% !important;
}

.kwali-range-calendar .react-datepicker__year-dropdown {
    width: 80% !important;
}

.kwali-calendar .react-datepicker__day--disabled {
    cursor: default !important;
    color: #ccc !important;
    background-color: white !important;
}

.kwali-range-calendar .react-datepicker__day--disabled {
    cursor: default;
    color: #ccc !important;
}

.kwali-range-calendar .react-datepicker__navigation--previous {
    left: 32px;
    top: 28px;
}

.kwali-range-calendar .react-datepicker__navigation--next {
    right: 32px;
    top: 28px;
}

.kwali-range-calendar .react-datepicker__day--selecting-range-start,
.kwali-range-calendar .react-datepicker__day--selecting-range-end {
    background-color: #2863c8 !important;
    color: white !important;
    border-radius: 50px !important;
}

@media (max-width: 767px) {
    .kwali-range-calendar .react-datepicker__month-container {
        width: 225px;
        padding: 25px 25px 25px;
    }
    .kwali-calendar .react-datepicker__month-container {
        width: 225px;
        padding: 0 25px;
    }
    .kwali-range-calendar .react-datepicker__month-container:last-child {
        padding-top: 0;
    }
}

@media (min-width: 767px) {    
    .kwali-range-calendar .react-datepicker__month-container {
        width: 224px;
        padding: 37px 32px 35px;
    }
}