.wrapper {
    padding: 0;
    border: 1px solid #E1E3E6;
    box-sizing: border-box;
    width: calc((100% - (20px * 4)) / 5);
    cursor: pointer;
    border-radius: 13px;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1))
}

.image {
    border-radius: 5px 5px 0 0;
    width: 100%
}

.legendWrapper {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
}

.commentButton {
    color: #2864C8;
    margin-right: 9px;
    font-size: 18px;
}

.legend {
    font-weight: normal;
    font-size: 12px;
    color: #11111B;
}

.information {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    text-align: left;
}

.specialty {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: block;
}

.crust {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    opacity: 0.7;
    display: block;
    text-transform: capitalize;
}

.imageWrapper {
    position: relative;
    display: flex;
}

.overlayScore {
    display: none;
}

.body {
    padding: 10px 10px;
}

.feedbackProperties {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.hidden {
    font-weight: bold;
    font-size: 10px;
    line-height: 24px;
    color: red;
    display: block;
}

.qaInspector {
    font-weight: bold;
    font-size: 10px;
    line-height: 24px;
    display: block;
}

.gtCategory {
    font-weight: normal;
    font-size: 10px;
    line-height: 24px;
    color: #11111B;
    opacity: 0.4;
}

@media (max-width: 767px) {
    .wrapper {
        width: calc((100% - (20px * 1)) / 2);
    }

    .score {
        display: none;
    }

    .overlayScore {
        display: block;
        position: absolute;
        bottom: 5px;
        right: 5px;
        background-color: white;
        border-radius: 12px;
    }
}
