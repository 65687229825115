.wrapper {
    width: 100%;
    box-sizing: border-box;
    background: #11111B;
    display: flex;
    justify-content: space-between;
    padding: 0 50px 0;
    position: sticky;
    z-index: 100;
    top: 0;
}

.mobile {
    display: none;
}

.desktop {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.leftCol {
    display: flex;
    height: fit-content;
}

.rightCol {
    display: flex;
}

.leftCol > a {
    padding: 0 39px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: #FFFFFF64;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}

.leftCol > a:nth-child(2n) {
    color: white;
}

.logo {
    padding: 11px 45px 11px 0;
}

.logo, .logo img {
    height: 26px;
}

.rightCol span:last-child {
    padding-right: 0;
    border-right: 0;
}

.wrapper a {
    color: white;
    text-decoration: none;
}

.wrapper a:hover {
    text-decoration: underline;
}

.franchise {
    padding: 17px 32px;
    border-right: 1px solid #FFFFFF32;
    border-left: 1px solid #FFFFFF32;
}

.franchise, .franchise img {
    width: 83px;
    height: 36px;
}

.adminButton, .userButton {
    background: none;
    border: none;
    position: relative;
}

.adminButton {
    margin-right: 26px;
}

.reportMenuButton {
    padding: 0 39px;
    margin-right: 0;
}

.reportMenuButton .buttonArt span{
    color: white;
    font-size: 11px;
    line-height: 18px;
    font-weight: 600;
    text-transform: uppercase;
}

.buttonArt {
    display: flex;
    justify-content: center;
    align-items: center;
}

.userButton span {
    background: #2864C8;
    color: white;
    border-radius: 50px;
    width: 32px;
    height: 32px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}

.miniMenu {
    display: flex;
    flex-direction: column;
    background: white;
    align-items: flex-start;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 0px 30px 5px rgb(0 0 0 / 30%);
}

.miniMenuWrapper {
    position: absolute;
    right: 0;
    width: 200px;
}

.miniMenu a {
    box-sizing: border-box;
    width: 100%;
    color: #11111B;
    text-decoration: none;
    text-align: left;
    padding: 11px 8px;
    font-size: 14px;
}

.miniMenu a:hover {
    background: #2864C8;
    color: white;
    text-decoration: none;
}

@media (max-width: 767px) {
    .wrapper {
        padding: 0;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
    }

    .logo {
        padding: 21px 0;
        border-right: none;
    }

    .burger {
        position: absolute;
        top: 27px;
        height: 14px;
        left: 20px;
    }

    .burger button {
        border: none;
        background: none;
    }

    .burgerMenu {
        background: #11111B;
        width: 100%;
        height: 100vh;
    }

    .burgerMenu ul{
        color: white;
        padding: 0;
        list-style: none;
    }

    .burgerMenu ul li {
        margin-bottom: 15px;
    }
}