.wrapper {
    position: relative;
    color: #11111B;
}

.boxWrapper {
    background: #FFFFFF;
    border: 1px solid #E1E3E6;
    padding: 6px 15px;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 187px;
}

.boxWrapper.main {
    font-size: 15px;
    line-height: 24px;
    padding: 14px 15px 12px
}

.boxWrapper.main svg {
    font-size: 13px;
}

.boxWrapper.main span {
    font-size: 15px;
}

.boxWrapperLabel {
    position: absolute;
    top: -5.5px;
    left: 10px;
    background-color: white;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    opacity: 0.5;
    padding: 0 4px;
}

.optionsWrapper {
    position: absolute;
    background: #FFFFFF;
    border: 1px solid #E1E3E6;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    z-index: 1;
}
.selectionWrapper {
    padding: 20px 15px 15px;
}

.selection {
    max-height: 275px;
    padding-bottom: 15px;
    border-bottom: 1px solid #E1E3E6;
    margin-bottom: 15px;
}

.options {
    max-height: 275px;
}

.selection, .options {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.option {
    width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 5px 5px 10px;
    box-sizing: border-box;
    margin-right: 10px;
}

.option:focus-within {
    background-color: #eaffea;
    mix-blend-mode: multiply;
    border-radius: 4px;
}

.option.selected {
    background-color: #EAF2FF;
    mix-blend-mode: multiply;
    border-radius: 4px;
}

.option.disabled {
    background-color: #d5d5d5;
    mix-blend-mode: multiply;
    border-radius: 4px;
}

.option label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    width: calc(100% - 20px);
    text-align: left;

}

.option:focus-within label {
    color: #28c838;
}

.option.selected label {
    color: #2864C8;
}

.option.disabled label {
    text-decoration: line-through;
}

.buttonsWrapper {
    display: flex;
    border-top: 1px solid #E1E3E6;
}

.selectAll {
    width: 50%;
    height: 56px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: 1px solid #E1E3E6;
}

.selectAll label {
    color: #11111B7D;
    text-align: right;
    width: calc(100% - 20px);
    cursor: pointer;
}

.clearApply {
    width: 50%;
    height: 56px;
    /* padding: 0 20px 0; */
    display: flex;
    /* padding: 20px 20px; */
    justify-content: space-between;
}

.clearApply button {
    width: 50%;
    font-family: 'Sora', sans-serif;
    border: none;
    background: none;
    color: #11111B7D;
    cursor: pointer;
}

.clearApply button, .selectAll label {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
}

.clearApply button:hover, .selectAll label:hover {
    color: #2864C8;
}

.selectMobile {
    display: none;
}

.highlight {
    background-color: #4bd1ff !important;
}

@media (max-width: 767px) {
    .boxWrapper {
        width: 100%;
        color: #11111B;
        height: 40px;
        font-size: 13px;
    }

    .boxWrapper.main {
        height: 52px;
        font-size: 13px;
        line-height: 24px;
        padding: 14px 15px 12px;
    }

    .boxWrapper.main svg {
        font-size: 13px;
    }
    
    .boxWrapper.main span {
        font-size: 13px;
    }

    .selectMobile {
        display: block;
    }

    .selectDesktop {
        display: none;
    }
}