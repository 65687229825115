.wrapper {
    background: white;
    text-align: left;
    max-width: 1140px;
    margin: auto;
    padding: 20px 0 220px;
}

.col1, .col2, .col2WithTitle {
    margin-bottom: 46px;
}

.col2, .col3, .col2WithTitle {
    display: flex;
    justify-content: space-between;
}

.col1 > div {
    position: relative;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
    border-radius: 13px;
    overflow: hidden;
}

.col2 > div {
    width: calc((100% - 20px) / 2);
    position: relative;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
    border-radius: 13px;
    overflow: hidden;
}

.col2WithTitle > div {
    width: calc((100% - 20px) / 2);
}

.col2WithTitle > div > div {
    position: relative;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
    border-radius: 13px;
    overflow: hidden;
}

.col3 > div {
    width: calc((100% - 40px) / 3);
    position: relative;
}

.aggregatedChart {
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
    border-radius: 13px;
    overflow: hidden;
    margin-bottom: 46px;
}

.aggregatedChartHeader {
    display: flex;
    gap: 117px;
    align-items: center;
    margin-top: 25px;
}

.aggregatedChartTitle {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin-left: 30px;
}

.aggregatedChart .charts {
    display: flex;
    justify-content: space-between;
}

.aggregatedChart .charts > div {
    width: calc((100% - 20px) / 2);
    position: relative;
}



.tooltip {
    position: fixed;
    z-index: 10;
    box-sizing: border-box;
}

.tooltiptext {
    visibility: visible;
    width: 130px;
    background-color: #555;
    color: #fff;
    text-align: left;
    border: 1px solid white;
    font-size: 13px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 130%;
    left: 85%;
    margin-left: -140%;
    opacity: 1;
    transition: opacity 0.3s;
    padding: 5px 5px 5px 1px;
}

.tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}

.leftWall {
    height: calc(100% + 16px);
    width: 8px;
    position: absolute;
    right: 100%;
    top: -8px;
    background: red;
}

.rightWall {
    height: calc(100% + 16px);
    width: 8px;
    position: absolute;
    left: 100%;
    top: -8px;
    background: red;
}

.bottomWall {
    height: 8px;
    width: calc(100% + 16px);
    position: absolute;
    top: 100%;
    left: -8px;
    background: red;
}

.topWall {
    height: 8px;
    width: calc(100% + 16px);
    position: absolute;
    bottom: 100%;
    left: -8px;
    background: red;
}


@media (max-width: 767px) {
    .wrapper {
        padding: 25px 40px 0 10px;
    }

    .aggregatedChart {
        margin-bottom: 40px;
    }

    .aggregatedChart .charts {
        flex-wrap: wrap;
    }

    .aggregatedChart .charts > div {
        width: 100%;
    }

    .aggregatedChartHeader {
        gap: 0;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 25px;
    }

    .aggregatedChartTitle {
        margin: 0;
        margin-bottom: 15px;
    }

    .col2, .col2WithTitle {
        flex-wrap: wrap;
        gap: 40px;
        margin-bottom: 40px;
    }

    .col1 {
        margin-bottom: 40px;
    }

    .col2 > div,
    .col2WithTitle > div {
        width: 100%;
    }
}