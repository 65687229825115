.wrapper {
    /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05); */
    border-radius: 8px;
    background: white;
}

.title {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
}

.plotHeader, .plotFooter {
    display: flex;
    justify-content: space-between;
    padding: 30px 30px 15px;
}

.plotFooter {
    display: flex;
    justify-content: center;
    padding: 10px 30px 10px;
}

.plotFilters {
    display: flex;
    gap: 20px;
    padding: 0 30px 0;
    min-height: 38px;
}

.legend {
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.legendItem {
    display: flex;
    justify-content: space-between;
    gap: 13px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: #11111BE6;
    align-items: center;
}

.legendItemColor {
    width: 25px;
    height: 25px;
    display: block;
}

.lineLegendItemColor {
    width: 25px;
    height: 2px;
    display: block;
    background: repeating-linear-gradient(to right,#03022933 0,#03022933 5px,transparent 5px,transparent 7px)
}

@media (max-width: 767px) {
    .plotHeader {
        flex-direction: column;
        gap: 20px;
    }

    .legendItemColor {
        width: 15px;
        height: 15px;
        min-width: 15px;
    }

    .legendItem {
        font-size: 12px;
        align-items: center;
        gap: 10px;
    }

    .legend {
        gap: inherit;
        width: 100%;
    }
}