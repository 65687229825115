.wrapper {
    background: white;
    text-align: left;
    width: 1700px;
    margin: auto;
    padding: 50px 0 220px;
}

.pageContent {
    position: relative;
}

.reportPage {
    padding-top: 3.7%;
    padding-left: 9%;
    padding-right: 9%;
    padding-bottom: 3.7%;
    height: calc(1700px * 1.5);
    min-height: calc(100% * 1.5);
    max-height: calc(100% * 1.5);
    border: 1px solid lightgrey;
    box-sizing: border-box;
    position: relative;
}

.pageTitle {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 55px;
}

.weeklyPerformanceTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 2%;
    margin-bottom: 9.8%;
}

.weeklyPerformanceTable td {
    text-align: center;
    font-size: 50px;
    width: calc(60% / 3);
}

.bordered {
    border: 1px solid black;
    padding: 5% 0;
}

.main {
    font-weight: bold;
}

.firstCol {
    width: 40% !important;
    padding: inherit !important;
}

.firstCol div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;
    padding: 0 5%;
}

.firstCol img {
    width: 23%;
}

.features {
    display: flex;
    justify-content: space-between;
}

.feature {
    width: 47%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.feature img {
    width: 100%;
}

.featureInfo {
    width: 85%;
    display: flex;
    box-sizing: border-box;
    position: absolute;
    bottom: 50px;
    left: 0;
    background-image: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));
}

.featureLegend {
    width: 100%;
    font-size: 50px;
    padding-left: 2%;
}

.featureIcon {
    width: 120px !important;
    position: absolute;
    top: 50%;
    right: -60px;
    margin-top: -60px;
}

.kwaliSeal {
    position: absolute;
    right: 9%;
    bottom: 3.7%;
    width: 15%;
}

.coverBrand {
    position: absolute;
    width: 1000px;
    left: 50%;
    margin-left: -500px;
    top: 300px
}

.coverInfo {
    position: absolute;
    top: 700px;
    left: 0;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    border-top: 1px solid #bfbfbf;
    border-right: 1px solid #bfbfbf;
    border-bottom: 1px solid #bfbfbf;
    background-color: #FFFFFF4D;
    width: 88%;
    text-align: right;
    padding: 80px 80px 80px 0;
    box-sizing: border-box;
}

.coverInfo span {
    display: block;
    color: black;
}

.coverInfoTitle {
    font-size: 80px;
    margin-bottom: 80px;
}

.coverInfoDescription {
    font-size: 60px;
    font-weight: 200;
    line-height: 2;
}



.issues {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 4%;
}

.issue {
    width: calc((100% - (10px * 2))/ 3);
    display: flex;
    flex-direction: column;
    position: relative;
}

.issue img {
    width: 100%;
}




.cards {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 1%;
    justify-content: flex-start;
    gap: 10px;
}

.card {
    width: calc((100% - (10px * 4))/ 5);
    display: flex;
    flex-direction: column;
    position: relative;
}

.card img {
    width: 100%;
}

.cardInfo {
    width: 85%;
    display: flex;
    box-sizing: border-box;
    position: absolute;
    bottom: 15px;
    left: 0;
}

.cardLegend {
    width: 100%;
    font-size: 25px;
    padding-left: 2%;
    min-height: 31.5px;
}

.cardIcon {
    width: 40px !important;
    position: absolute;
    top: 50%;
    right: -20px;
    margin-top: -20px;
}


.tipBlock {
    margin-top: 9.8%;
}

.tipTitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.tipIcon {
    width: 120px;
}

.tipText {
    font-size: 50px;
}

.legend {
    width: 100%;
    display: flex;
    gap: 50px;
    justify-content: center;
    margin-top: 5%;
}

.legendItem {
    display: flex;
    gap: 10px;
    align-items: center;
}

.legendItem span {
    font-size: 30px;
    display: block;
}

.square {
    width: 30px;
    height: 30px;
}

.circle {
    width: 31px;
    height: 31px;
    border-radius: 50%;
}

.steps {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    margin-left: -87.5px;
    left: 50%;
}

.step {
    border-radius: 50%;
    border: 1px solid#4472c4;
    font-size: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.stepSeparator {
    border: 1px solid#4472c4;
    width: 50px;
    height: 3px;
    box-sizing: border-box;
}

.reportTitle {
    display: block;
    margin-bottom: 25px;
}

.flowButtons {
    display: flex;
    justify-content: space-between;
    width: 250px;
    position: fixed;
    bottom: 30px;
    z-index: 2;
    right: 30px;
    background-color: white;
    padding: 8px;
    border-radius: 10px;
}

.flowButton {
    width: 45%;
    padding: 5px 0;
}

.ranking {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.rankingRow {
    line-height: 90px;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    font-weight: 500;
    font-size: 25px;
    color: #7f7f7f;
}

.percentageBar {
    height: 55px;
    display: inline-block;
    border: 3px solid #bdbdbd;
    border-left: none;
    box-sizing: border-box;
}

.chartsWrapper {
    width: 100%;
    margin-top: 30px;
}

.charts {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.specialtyChart {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chartLegend {
    width: 14%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 25px;
    text-align: center;
    align-items: center;
    font-weight: bold;
    gap: 25px;
}

.chartLegend img {
    width: 100%;
}

.plot {
    width: 82%;
    border: 1px solid #bfbfbf;
}

.disclaimer {
    font-size: 30px;
    text-align: center;
    display: block;
    color: #7f7f7f;
    margin-top: 1%;
}

.rankingBar {
    width: 100%;
    display: flex;
    gap: 20px;
    align-items: center;
}

.delta {
    display: block;
    width: 30%;
    line-height: 40px;
    font-weight: 500;
    font-size: 30px;
}

.deltaPositive {
    color: #1ba31b;
}

.deltaNegative {
    color: #fe0808;
}

.deltaZero {
    color: black;
}

.storesEvolutionTable {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    font-size: 17px;
}

.storesEvolutionTable td {
    border: 2px solid #e4e4e4;
}

.storesEvolutionTable td:first-child {
    width: 25.5%;
}

.inspectedPictureWrapper {
    width: fit-content;
    position: relative;
    display: flex;
}

.inspectedPicture {
    width: 800px;
}

.closeInspectedPictureButton {
    position: absolute;
    top: 0;
    right: 0;
    background: white;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.resumeTable {
    width: 100%;
    text-align: center;
    font-size: 30px;
    line-height: 30px;
    border-collapse: collapse;
    margin-top: 70px;
}

.resumeTable td, .resumeTable th {
    border: 2px solid black;
    padding: 17px 0;
}

.rankingTable {
    width: 100%;
    border-collapse: collapse;
}

.rankingTable th {
    font-size: 25px;
    text-align: center;
    border-bottom: 3px solid black;
}

.layout1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    margin-bottom: 100px;
}

.layout1Title {
    font-size: 40px;
    width: 20%;
    text-align: left;
}

.layout1Table {
    border-collapse: collapse;
    font-size: 40px;
    width: 78%
}

.layout1Table th {
    background-color: #5b9bd5;
    color: white;
}

.layout1Table td, .layout1Table th {
    width: 12%;
}

.borderedRow td {
    border: 1px solid #bdbdbd;
}

.layout1legend {
    display: flex;
    justify-content: space-evenly;
    margin-top: 80px;
}

.layout1legendItem {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.layout1legendText {
    font-size: 40px;
}

.layout1legendColorIndicator {
    height: 49px;
    width: 190px;
    border: 1px solid #bdbdbd;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: 600;
}

.layout1RightCol {
    width: 80%;
    display: flex;
    align-items: center;
}

.layout1RightColIcon {
    height: 250px;
    z-index: 2;
}

.layout1RightColPercentageBar {
    height: 150px;
    /*background-image: linear-gradient(to right, #ffffff, #f3e6fb, #f4cbeb, #fbaccf, #ff8ea7, #ff8192, #fe767a, #f96c61, #f96c61, #f96c61, #f96c61, #f96c61);*/
    background-color: #f96c61;
    display: flex;
    align-items: center;
    z-index: 1;
}

.layout1RightColPercentageBarInnerLabel {
    width: 100%;
    text-align: right;
    padding-right: 40px;
    font-weight: 500;
    font-size: 40px;
    color: white;
}

.layout1RightColPercentageBarOuterLabel {
    font-size: 40px;
    font-weight: 500;
    padding-left: 60px;
}