.wrapper {
    position: relative;
    color: #11111B;
}

.boxWrapper {
    background: #FFFFFF;
    border: 1px solid #E1E3E6;
    padding: 6px 15px;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 187px;
}

.rangeWrapper {
    position: absolute;
    background: #FFFFFF;
    border: 1px solid #E1E3E6;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    z-index: 1;
    width: 360px;
}

.buttonsWrapper {
    display: flex;
    border-top: 1px solid #E1E3E6;
}

.clearApply {
    width: 100%;
    height: 56px;
    padding: 0;
    display: flex;
    justify-content: space-around;
}

.clearApply button {
    font-family: 'Sora', sans-serif;
    border: none;
    background: none;
    color: #11111B7D;
    width: 50%;
    cursor: pointer;
}

.clearApply button {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
}

.clearApply button:hover {
    color: #2864C8;
}

.boxWrapperLabel {
    position: absolute;
    top: -5.5px;
    left: 10px;
    background-color: white;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    opacity: 0.5;
    padding: 0 4px;
}

.highlight {
    background-color: #4bd1ff !important;
}

@media (max-width: 767px) {
    .boxWrapper {
        width: 100%;
        font-size: 13px;
    }
}