.wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid #a5a5a5;
    padding: 10px 40px;
    border-radius: 20px;
    align-items: center;
    width: 240px;
}

.title {
    font-weight: bold;
    margin-bottom: 15px;
}

.row {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    width: 100%;
    margin-top: 5px;
}

.rowSeparator {
    border-bottom: 1px solid black;
}

.subtitle {
    font-weight: bold;
    margin-bottom: 13px;
    margin-top: 15px;
}

.condition {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
}

.buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.button {
    width: 45%;
    padding: 5px 0;
}

.separator {
    border-top: 1px solid #bfbfbf;
    width: 100%;
}