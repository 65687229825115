.wrapper {
    display: flex;
    width: fit-content;
    height: fit-content;
}

.step {
    border-right: 1px solid #E1E3E6;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    padding: 7px 20px;
    color: #11111BAF ;
    min-height: 38px;
    display: flex;
    align-items: center;
}

.step.small {
    min-height: inherit;
    padding: 0 10px;
}

.step.small span {
    line-height: inherit;
}

.step span {
    text-transform: capitalize;
}

.step:first-child {
    padding-left: 0;
}

.step:last-child {
    border-right: none;
}

@media (max-width: 767px) {
    .step:last-child {
        padding-right: 0;
    }
}