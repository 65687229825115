.wrapper {
    background: white;
    text-align: left;
    max-width: 1140px;
    margin: auto;
    padding: 50px 0 220px;
}

.venuesTable td,
.venuesTable th {
    padding: 10px;
}

.venuesTable th {
    text-align: center;
}

.stateButton {
    border: 1px solid #bfbfbf;
    border-radius: 0;
    color: white;
    padding: 5px 10px;
    width: 200px;
}

.blockedCheck {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 13px;
}

.sendPDFButton {
    border: 1px solid #bfbfbf;
    border-radius: 0;
    background-color: #00b050;
    color: #FFFFFF;
    padding: 5px 10px;
    width: 300px;
}