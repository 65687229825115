.wrapper {
    width: 100%;
}

.wrapper table{
    width: 100%;
}

.wrapper table td{
    border-radius: 50%;
    border: 1px solid #4472c4;
}

.wrapper table td,
.wrapper table th {
    text-align: center;
    font-size: 13px;
    width: 17px;
    height: 17px;
    box-sizing: border-box;
}