.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #00000099;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper {
    background-color: white;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

.dialogHeader {
    display: flex;
    justify-content: flex-end;
    padding: 25px 25px 13px 25px;
}

.closeButton {
    width: 12px;
    height: 12px;
    cursor: pointer;
}

.dialogTitle {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 20px;
    padding-bottom: 15px;
}

.dialogBody {
    padding: 15px 40px;
}

.dialogBody textarea {
    width: 320px;
    font-family: 'Sora', sans-serif !important;
    border: 1px solid #E1E3E6;
    box-sizing: border-box;
    border-radius: 4px;
}

.dialogFooter {
    padding: 15px 40px 30px;
}

.dialogFooter button {
    width: 100%;
    background: #2864C8;
    border-radius: 77px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: white;
    box-shadow: none;
    border: none;
    cursor: pointer;
    padding: 14px 0;
}