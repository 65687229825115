.wrapper {
  width: 100%;
  padding: 30px 50px;
  box-sizing: border-box;
  text-align: left;
}

.title {
  display: block;
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #11111B;
  opacity: 0.7;
}

.scoreCards {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 8%);
}

.featuresRow {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 22px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
  border-radius: 13px;
  width: 100%;
}

.feature {
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-bottom: 16px;
  padding-top: 16px;
}

.featureContent {
  display: flex;
  width: 100%;
  padding-left: 30%;
}

.featureImg {
  display: flex;
  align-items: center;
  margin-right: 14px;
}

.featureDetail {
  display: flex;
  flex-direction: column;
}

.featureLabel {
  font-size: 15px;
  color: #8E8E93;
  line-height: 20px;
}

.featureValue {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}


@media (max-width: 767px) {
  .feature {
    width: 100%;
  }

  .wrapper {
    padding: 15px;
    flex-wrap: wrap;
  }

  .scoreCards {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: inherit;
  }
}
