.wrapper {
    position: relative;
}

.title {
    font-weight: bold;
}

.dialogHeader {
    padding: 25px;
}

.dialogTitle {
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.dialogBody {
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.dialogBody p {
    margin-bottom: 0;
    margin-top: 20px;
}

.dialogFooter {
    border-top: 1px solid #11111b47;
    display: flex;
    justify-content: flex-end;
    padding: 25px;
    gap: 25px;
}

.dialogFooter button {
    width: 121px;
    background: #2864C8;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: white;
    box-shadow: none;
    border: none;
    cursor: pointer;
    padding: 8px 0;
}

.dialogFooter button:first-child {
    border: 1px solid rgba(86, 103, 137, 0.2);;
    background: white;
    color: #84868A; 
}

.defaultVenue {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    margin-top: 20px;
}

.defaultVenuesWrapper {
    max-height: 300px;
    overflow-y: scroll;
    padding-right: 10px;
}

.deleteButton {
    background: none;
    border: none;
    cursor: pointer;
}
