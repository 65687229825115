.wrapper {
    background: white;
    text-align: left;
    margin: auto;
    padding: 30px 50px 10px;
}

.refreshButton {
    display: block;
    position: absolute;
    top: 78px;
    right: 50px;
    background-color: #a8d08c;
    border: 1px solid #E1E3E6;
    border-radius: 4px;
    color: white;
    padding: 6px 15px;
    line-height: 24px;
}

.sendAllButton {
    background-color: #a8d08c;
    border: 1px solid #E1E3E6;
    border-radius: 4px;
    color: white;
    padding: 6px 15px;
    width: 100%;
}

.pageContent {
    display: flex;
    font-size: 12px;
    gap: 10px;
}

.companyResume {
    border: 1px solid gray;
    width: calc((100% - 10px) * 0.25);
    padding: 34px 10px 0;
}

.companyResumeTable {
    border-collapse: collapse;
    width: 100%;
    line-height: 2;
}

.highlightRow > td {
    background-color: #d6e0f2;
}


.companyResumeTable th,
.companyResumeTable td {
    text-align: center;
}

.square {
    border: 1px solid #505050;
    background-color: white;
}

.deltaZero {
    background-color: #ffffff !important;
}

.deltaNegative {
    background-color: #fe9999 !important;
}

.deltaPositive {
    background-color: #e1eed8 !important;
}

.storeResume {
    width: calc((100% - 10px) * 0.75);
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 750px;
    overflow-y: scroll;
}

.firstRow,
.secondRow {
    border: 1px solid gray;
    padding: 20px 10px 20px;
}

.storeResumeTable {
    border-collapse: collapse;
}

.storeResumeTable > tbody > tr > td:nth-child(1) {
    width: 43%;
}

.storeResumeTable > tbody > tr > td:nth-child(2) {
    width: 7%;
}

.storeResumeTable > tbody > tr > td:nth-child(3) {
    width: 25%;
}

.storeResumeTable > tbody > tr > td:nth-child(4) {
    width: 25%;
}

.storeResumeTable > tbody > tr >  th {
    text-align: center;
}

.storeResumeTable > tbody > tr >  td {
    border: 1px solid gray;
    padding: 5px;
}

.pizzaCountTable {
    width: 100%;
    line-height: 2;
}

.pizzaCountTable td {
    border: none;
    text-align: center;
}

.simulatorTable,
.stressTestTable {
    width: 100%;
}

.simulatorTable th,
.simulatorTable td,
.stressTestTable th,
.stressTestTable td {
    border: 1px solid grey;
    text-align: center;
    width: 20%;
}

.simulatorTable th:nth-child(2) {
    background-color: #fe0000;
    color: white;
}

.simulatorTable th:nth-child(3) {
    background-color: #12b050;
    color: white;
}

.simulatorTable td,
.stressTestTable td {
    line-height: 6;
    background-color: #efefef;
}

.simulatorTable td input {
    border: none;
    padding: 0;
    width: 100%;
    text-align: center;
    line-height: 5;
}


.features {
    display: flex;
    justify-content: space-between;
}

.feature {
    width: 47%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.feature img {
    width: 100%;
}

.featureInfo {
    width: 85%;
    display: flex;
    box-sizing: border-box;
    position: absolute;
    bottom: 50px;
    left: 0;
    background-image: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));
}

.featureLegend {
    width: 100%;
    font-size: 50px;
    padding-left: 2%;
}

.featureIcon {
    width: 120px !important;
    position: absolute;
    top: 50%;
    right: -60px;
    margin-top: -60px;
}

.cards {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 1%;
    justify-content: flex-start;
    gap: 10px;
}

.shiftedCards {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
}

.card {
    width: calc((100% - (10px * 4))/ 5);
    display: flex;
    flex-direction: column;
    position: relative;
}

.card img {
    width: 100%;
}

.cardInfo {
    width: 85%;
    display: flex;
    box-sizing: border-box;
    position: absolute;
    bottom: 15px;
    left: 0;
    background-image: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));
}

.cardLegend {
    width: 100%;
    font-size: 25px;
    padding-left: 2%;
}

.cardIcon {
    width: 40px !important;
    position: absolute;
    top: 50%;
    right: -20px;
    margin-top: -20px;
}

.inspectedPictureWrapper {
    width: fit-content;
    position: relative;
    display: flex;
}

.inspectedPicture {
    width: 800px;
}

.closeInspectedPictureButton {
    position: absolute;
    top: 0;
    right: 0;
    background: white;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.downloadReportButton {
    border: none;
    background: none;
    padding: 0;
    font-size: 12px;
}

.storesEvolutionTable {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    font-size: 25px;
}

.storesEvolutionTable td {
    border: 2px solid #e4e4e4;
    width: calc((100% - 20%) / 12);
}

.storesEvolutionTable td:first-child {
    width: 20%;
}

.cardID {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    background-color: #ffffffb3;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 30px;
}