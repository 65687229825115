
.title {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 15px;
    margin-left: 30px;
    margin-top: 30px;
}

.subTitle {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 15px;
    margin-left: 44px;
    margin-top: 30px;
}

@media (max-width: 767px) {
    .subTitle {
        display: block;
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 15px;
        margin-left: 44px;
        margin-top: 30px;
    }
}
