.information {
    display: flex;
    justify-content: space-between;
}

.name {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
}

.score {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #2864C8;
}

.bar {
    width: 100%;
    background-color: #EAF2FF;
    border-radius: 50px;
    height: 7px;
}

.filler {
    height: 100%;
    background-color: #2864C8;
    border-radius: inherit;
    text-align: right
}

.label {
    padding: 5;
    color: white;
    font-weight: bold
}

.scoreWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}