.wrapper {
    padding-right: 5px;
}

.wrapper .input-range {
    margin: 0 !important;
    margin-right: 11px !important;
    width: auto !important;
}

.wrapper .input-range__label-container {
    display: none;
    font-family: 'Sora', sans-serif;
    font-size: 14px;
    color: #11111B;
    background: white;
}

.wrapper  .input-range__slider {
    background-color: white;
    margin-top: -14px;
    margin-left: -10px;
    border-color: #2864C8;
    height: 19px;
    width: 19px;
}

.wrapper .input-range__track.input-range__track--active {
    background: #2864C8;
}

.wrapper .input-range__track {
    height: 7px !important;
}

.wrapper .input-range--disabled .input-range__slider {
    display: none;
}