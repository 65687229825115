.wrapper {
    background: white;
    text-align: left;
    margin: auto;
    padding: 30px 50px 10px;
}

.pageContent {
    display: flex;
    font-size: 12px;
    gap: 10px;
}

.refreshButton {
    display: block;
    position: absolute;
    top: 78px;
    right: 50px;
    background-color: #a8d08c;
    border: 1px solid #E1E3E6;
    border-radius: 4px;
    color: white;
    padding: 6px 15px;
    line-height: 24px;
}