.usersTable {
    width: 100%;
    position: relative;
}

.usersTableRow, .usersTableHeader {
    display: flex;
}

.usersTableRow {
    padding-bottom: 21px;
    padding-top: 21px;
    border-bottom: 1px solid #69698510;
}

.usersTableHeader {
    font-weight: 500;
}

.usersTableCel, .usersTableHeader {
    width: 30%;
}

.usersTableCel.right, .usersTableHeader.right {
    width: 10%;
    text-align: right;
    position: relative;
}

.buttonsWrapper {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 30px 5px rgb(0 0 0 / 15%);
    width: fit-content;
    position: absolute;
    z-index: 4;
    right: 0;
}

.buttonsWrapper button {
    text-align: left;
    background: white;
    border: none;
    cursor: pointer;
    width: 170px;
    padding: 10px 15px;
}

.buttonsWrapper button:hover {
    color: white;
    background: #2864C8;
}

.toggleButton {
    font-weight: bolder;
    border: none;
    background: none;
    cursor: pointer;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
}

.paginationButtons {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    padding: 7px 6.7px;
}

.paginationButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
}

.paginationButton.disabled {
    cursor: not-allowed;
    pointer-events: none !important; 
}

.paginationButton.disabled svg path {
    fill: rgba(0, 0, 0, 0.25);
}


@media (max-width: 767px) {
    .usersTableRow .usersTableHeader:nth-child(3),
    .usersTableRow .usersTableHeader:nth-child(2),
    .usersTableRow .usersTableCel:nth-child(3),
    .usersTableRow .usersTableCel:nth-child(2) {
        display: none;
    }

    .usersTableRow {
        justify-content: space-between;
    }
}