.wrapper {
    margin-bottom: 94px;
}

.userFormInputs {
    display: flex;
    gap: 12px;
}

.userFormCel {
    display: flex;
}

.userFormInput {
    border-radius: 4px;
    border: 1px solid #E1E3E6;
    padding: 6px 15px;
    line-height: 24px;
    box-sizing: border-box;
    width: 197px;
    font-size: 15px;
}

.userFormSubmit {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.submitButton {
    float: right;
    display: flex;
    padding: 8px 15px;
    cursor: pointer;
    background-color: #2864C8;
    border-radius: 8px;
    color: white;
    opacity: 1;
    border: None;
    align-items: center;
    width: 103px;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
}

@media (max-width: 767px) {
    .userFormInputs {
        flex-direction: column;
    }

    .userFormCel {
        justify-content: center;
    }

    .userFormCel input {
        width: 100%;
    }

    .userFormCel > div {
        width: 100%;
    }
}