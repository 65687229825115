.wrapper {
    display: flex;
    flex-direction: column;
}

.secondaryFilters {
    display: flex;
    gap: 15px;
    padding: 7.5px 0 30px 50px;
    border-bottom: 1px solid #E1E3E6;
}

.mainFilters {
    display: flex;
    gap: 15px;
    padding: 36px 0 15px 50px;
}

@media (max-width: 767px) {
    .mainFilters, .secondaryFilters {
        padding: 26px 15px 23px;
        flex-wrap: wrap;
    }

    .secondaryFilters {
        padding-top: 0;
    }

    .mainFilters > div, .secondaryFilters > div {
        width: calc((100% - 15px) / 2)
    }
}