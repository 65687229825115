.wrapper {
    background: white;
    text-align: left;
    width: 1700px;
    margin: auto;
    padding: 50px 0 220px;
}

.pageContent {
    position: relative;
}

.separator {
    border-top: 1px solid #bfbfbf;
    width: 100%;
}