.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #11111B;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}


::-webkit-scrollbar-track {
  background: darkgray;
  border-radius: 2px;
}


::-webkit-scrollbar-thumb {
  background-color: gray; 
  border-radius: 5px;
}


::-webkit-scrollbar-thumb:hover {
  background-color: #11111BBB; 
}

.input-range {
  margin: 37px 35px 37px !important;
  width: auto !important;
}

.input-range__label-container {
  font-family: 'Sora', sans-serif;
  font-size: 14px;
  color: #11111B;
  background: white;
}

.input-range .input-range__slider {
  background-color: white;
  margin-top: -0.8rem;
  border-color: #2864C8;
}

.input-range__track.input-range__track--active {
  background: #2864C8;
}

input[type='checkbox'] {
  margin: 0;
  width: 20px;
  height: 20px;
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  border: 1px solid #BFBFBF;
  box-sizing: content-box;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
}

input[type='checkbox'].large {
  width: 30px;
  height: 30px;
}

input[type='checkbox']:checked {
  background-color: #2864C8;
  border: 1px solid #2864C8;
  color: white;
}

input[type='checkbox']:checked::after {
  content: 'L';
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 6px;
  color: white;
  font-weight: bolder;
  transform: scaleX(-1) rotate(-35deg);
}

input[type='checkbox'].large:checked::after {
  font-size: 21px;
  left: 9px;
}

input[type='radio'] {
  margin: 0;
  width: 20px;
  height: 20px;
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  border: 1px solid #BFBFBF;
  box-sizing: content-box;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

input[type='radio']:checked {
  background-color: white;
  border: 1px solid #2864C8;
  color: white;
}

input[type='radio']:checked::after {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
  background-color: #2864C8;
}