.wrapper {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    overflow: hidden;
    background: white;
    box-sizing: border-box;
}

.title {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.tableWrapper {
    box-sizing: border-box;
    width: 100%;
    overflow-y: auto;
    padding-top: 21px;
    max-height: 473px;
    overflow-y: auto !important;
    padding: 0 30px;
}

.table tr th {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #24292E;
    padding-bottom: 23px;
    text-transform: capitalize;
}

.table tr td {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #24292E;
    padding-bottom: 15px;
    text-transform: capitalize;
}

.tableHeader {
    display: flex;
    justify-content: space-between;
    padding: 30px 30px 15px;
}

.tableFilters {
    display: flex;
    gap: 20px;
    padding: 0 30px 45px;
}

.tableData {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 100%;
}

.tableWithTotal tr:last-child td {
    padding-top: 15px;
    border-top: 1px solid black;
}

@media (max-width: 767px) {
    .table {
        padding: 0 15px;
    }

    .table tr td {
        font-size: 13px;
    }
}