.reportPage {
    padding-top: 3.7%;
    padding-left: 9%;
    padding-right: 9%;
    padding-bottom: 3.7%;
    height: calc(1700px * 1.5);
    min-height: calc(100% * 1.5);
    max-height: calc(100% * 1.5);
    border: 1px solid lightgrey;
    box-sizing: border-box;
    position: relative;
}

.pageTitle {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 55px;
    margin-top: 4.5%;
}

.weeklyPerformanceTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 2%;
    margin-bottom: 9.8%;
}

.weeklyPerformanceTable td {
    text-align: center;
    font-size: 50px;
    width: 50%;
}

.bordered {
    border: 1px solid black;
    padding: 5% 0;
}

.main {
    font-weight: bold;
}

.firstCol {
    padding: inherit !important;
}

.firstCol div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;
    padding: 0 5%;
}

.firstCol img {
    width: 23%;
}

.features {
    display: flex;
    justify-content: space-between;
}

.feature {
    width: 47%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.feature img {
    width: 100%;
}

.featureInfo {
    width: 85%;
    display: flex;
    box-sizing: border-box;
    position: absolute;
    bottom: 50px;
    left: 0;
    background-image: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));
}

.featureLegend {
    width: 100%;
    font-size: 50px;
    padding-left: 2%;
}

.featureIcon {
    width: 120px !important;
    position: absolute;
    top: 50%;
    right: -60px;
    margin-top: -60px;
}

.kwaliSeal {
    position: absolute;
    right: 9%;
    bottom: 3.7%;
    width: 15%;
}

.coverBrand {
    position: absolute;
    width: 1000px;
    left: 50%;
    margin-left: -500px;
    top: 300px
}

.coverInfo {
    position: absolute;
    top: 700px;
    left: 0;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    border-top: 1px solid #bfbfbf;
    border-right: 1px solid #bfbfbf;
    border-bottom: 1px solid #bfbfbf;
    background-color: #FFFFFF4D;
    width: 88%;
    text-align: right;
    padding: 80px 80px 80px 0;
    box-sizing: border-box;
}

.coverInfo span {
    display: block;
    color: black;
}

.coverInfoTitle {
    font-size: 80px;
    margin-bottom: 80px;
}

.coverInfoDescription {
    font-size: 60px;
    font-weight: 200;
    line-height: 2;
}



.issues {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 4%;
}

.issue {
    width: calc((100% - (10px * 2))/ 3);
    display: flex;
    flex-direction: column;
    position: relative;
}

.issue img {
    width: 100%;
}

.cards {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 1%;
    justify-content: flex-start;
    gap: 10px;
}

.shiftedCards {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
}

.card {
    width: calc((100% - (10px * 4))/ 5);
    display: flex;
    flex-direction: column;
    position: relative;
}

.card img {
    width: 100%;
}

.cardInfo {
    width: 85%;
    display: flex;
    box-sizing: border-box;
    position: absolute;
    bottom: 15px;
    left: 0;
    background-image: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));
}

.cardLegend {
    width: 100%;
    font-size: 25px;
    padding-left: 2%;
}

.cardIcon {
    width: 40px !important;
    position: absolute;
    top: 50%;
    right: -20px;
    margin-top: -20px;
}

.cardID {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    background-color: #ffffffb3;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 30px;
}

.tipBlock {
    margin-top: 9.8%;
}

.tipTitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.tipIcon {
    width: 120px;
}

.tipText {
    font-size: 50px;
}

.legend {
    width: 100%;
    display: flex;
    gap: 50px;
    justify-content: center;
    margin-top: 5%;
}

.legendItem {
    display: flex;
    gap: 10px;
    align-items: center;
}

.legendItem span {
    font-size: 30px;
    display: block;
}

.square {
    width: 30px;
    height: 30px;
}

.circle {
    width: 31px;
    height: 31px;
    border-radius: 50%;
}

.reportTitle {
    display: block;
    margin-bottom: 25px;
}

.flowButtons {
    display: flex;
    justify-content: space-between;
    width: 250px;
    position: fixed;
    bottom: 30px;
    z-index: 2;
    right: 30px;
    background-color: white;
    padding: 8px;
    border-radius: 10px;
}

.flowButton {
    width: 45%;
    padding: 5px 0;
}

.ranking {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.rankingRow {
    font-size: 35px;
    line-height: 35px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    margin-bottom: 40px;
    color: #5e5e59;
}

.rankingRow > span:first-child {
    width: 30%;
    padding-right: 20px;
    text-align: left;
    box-sizing: border-box;
}

.rankingRow > span:last-child {
    width: 10%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    margin-left: 2%;
}

.percentageBar {
    height: 20px;
    display: inline-block;
    border: 3px solid #95a3bb;
    box-sizing: border-box;
}

.podiumRow {
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;
    color: #595959;
}

.podiumRow .percentageBar {
    height: 55px;
}

.podiumIcon {
    height: 55px;
    margin-left: 10px;
}

.storeRow .rankingBar {
    background-color: #deebf7; 
}

.storeRow .percentageBar {
    background-color: #4472c4;
}

.chartsWrapper {
    width: 100%;
    margin-top: 100px;
}

.charts {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.specialtyChart {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chartLegend {
    width: 14%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 25px;
    text-align: center;
    align-items: center;
    font-weight: bold;
    gap: 25px;
}

.chartLegend img {
    width: 100%;
}

.plot {
    width: 82%;
    border: 1px solid #bfbfbf;
}

.disclaimer {
    font-size: 30px;
    text-align: center;
    display: block;
    color: #7f7f7f;
    margin-top: 1%;
}

.rankingBar {
    width: 60%;
    display: flex;
    gap: 20px;
    align-items: center;
}

.deltaPositive {
    color: #548135;
    background-color: #e1efd8;
    border: 2px solid #548135;
}

.deltaNegative {
    color: #c00000;
    background-color: #fbcfd0;
    border: 2px solid #c00000;
}

.deltaZero {
    color: black;
    background-color: white;
    border: 2px solid black;
}

.deltaTitle {
    width: 100%;
    text-align: right;
    font-size: 25px;
    margin-bottom: 20px;
}

.storesEvolutionTable {
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    font-size: 17px;
}

.storesEvolutionTable td {
    border: 2px solid #e4e4e4;
    width: calc((100% - 25.5%) / 12);
}

.storesEvolutionTable td:first-child {
    width: 25.5%;
}

.inspectedPictureWrapper {
    width: fit-content;
    position: relative;
    display: flex;
}

.inspectedPicture {
    width: 800px;
}

.closeInspectedPictureButton {
    position: absolute;
    top: 0;
    right: 0;
    background: white;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}