.loadingScreen {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    /*max-height: 100vh;*/
    left: 0;
    top: 0;
    border-radius: 13px;
}

.adaptable {
    position: absolute;
}

.spinnerWrapper {
    width: 85px;
    height: 85px;
    background: rgba(242, 242, 242, 0.8);
    border-radius: 14px;
    color: white;
    font-weight: 400;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinnerWrapper span {
    width: 44px;
    height: 44px;
    border: 4px solid #2864C8;
    border-bottom: 4px solid rgba(242, 242, 242, 0);
    border-radius: 50%;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}