.wrapper {
    zoom: 100%;
    display: flex;
    --border-transition: 0.3s;
    padding-top: 20px;
    padding-right: 50px;
    padding-bottom: 20px;
    padding-left: 50px;
    box-sizing: border-box;
}

.mainPanel {
    width: 70%;
    --border-transition: 0.3s;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #DDDDDD;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
    padding-top: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
}

.title {
    color: #2266c4;
    font-size: 100px;
    font-weight: 700;
    position: relative;
    top: -24px;
}

.carousels {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.carouselWrapper {
    width: calc((100% - 60px) / 2);
}

.carouselTitle {
    color: #000000;
    font-size: 70px;
    font-weight: 600;
    margin-bottom: 40px;
    display: block;
}

.carousel {
    display: flex;
    overflow: hidden;
    width: 100%;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #DDDDDD;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
    padding: 10px 0;
    box-sizing: border-box;
}

.card {
    min-width: 100%;
    transition: transform 0.5s ease-out;width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 10px;
}

.card img {
    width: 100%;
}

.cardInfo {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    position: absolute;
    bottom: 100px;
    left: 0;
    background-color: transparent;
}

.infoGreen {
    background-image: linear-gradient(193deg, #5D161621 0%, #118603 100%);
}

.infoRed {
    background-image: linear-gradient(193deg, #5D161621 0%, #FF0000 100%);
}

.cardLegend {
    width: 100%;
    color: #FFFFFF;
    font-family: "Sora", Sans-serif;
    font-size: 60px;
    text-align: left;
    font-weight: 700;
    padding-top: 20px;
    padding-right: 8px;
    padding-bottom: 20px;
    padding-left: 25px;
}

.cardIcon {
    width: 200px !important;
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -100px;
}

.cardIconCircle {
    width: 200px !important;
    height: 200px !important;
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -100px;
    background-color: white;
    border: 2px solid #f1b605;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.cardIconCircle img {
    width: 80%;
}

.tipWrapper {
    display: flex;
    align-items: center;
    margin-top: 70px;
}

.tipTextWrapper {
    margin: 2px 0px 2px -118px;
    padding: 70px 80px 70px 150px;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #BBBBBB;
    border-radius: 0% 0% 0% 0%;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
}

.tipText {
    color: #000000;
    font-size: 78px;
    font-weight: 600;
}

.tipIconWrapper {
    margin: 0px 0px 0px 0px;
    padding: 50px 50px 50px 50px;
    background-color: #FFFFFF;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #CCCCCC;
    border-radius: 1000% 1000% 1000% 1001%;
    box-shadow: 3px 5px 5px 0px rgb(0 0 0 / 50%);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 300px;
    max-width: 300px;
    height: 300px;
    gap: 25px;
}

.tipIcon {
    width: 130px;
    border-style: none;
    border-radius: 100% 100% 100% 100%;
    vertical-align: middle;
    display: inline-block;
}

.rightPanel {
    width: 30%;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #DDDDDD;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-top: 50px;
    justify-content: space-between;
}

.rightPanelTitle {
    color: #000000;
    font-size: 80px;
    font-weight: 600;
    display: block;
}

.rightPanelTitle2 {
    color: #000000;
    font-size: 60px;
    font-weight: 500;
    display: block;
}

.rightPanelIcon {
    width: 350px;
    z-index: 2;
}

.rightPanelCardWrapper {
    display: flex;
    align-items: center;
}

.rightPanelCardText {
    color: #000000;
    font-size: 80px;
    font-weight: 600;
}

.percentageBar {
    height: 80px;
    margin-left: -40px;
    box-shadow: 10px 10px 5px 0px #999999;
    margin-right: 30px;
}

.franchiseRanking {
    width: 100%;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #DDDDDD;
    box-shadow: 0 -10px 10px -10px rgb(0 0 0 / 50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 1000px;
    padding-top: 50px;
}

.rankingTable {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    margin-top: 80px;
}

.rankingRow {
    color: #000000;
    font-size: 45px;
    line-height: 45px;
    padding: 0 43px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
}

.rankingNotPrincipal {
    font-size: 35px;
    line-height: 35px;
    color: #000000;
    opacity: 0.3;
}

.rankingPrincipal {
    border: 3px solid #DDDDDD;
    box-shadow: 10px 10px 5px 0px #999999;
    border-radius: 50px;
    padding: 15px 40px;
    color: #000000;
    font-size: 45px;
    line-height: 45px;
    opacity: 1;
    font-weight: bold;
}

.rankingFirstCol {
    width: 85%
}

.rankingFirstCol table {
    width: 100%;
    text-align: left;
}

.rankingFirstCol table td:first-child{
    width: 12%;
    text-align: center;
}

.rankingFirstCol table td:nth-child(2){
    padding-left: 10px;
}
