.cards {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 1%;
    justify-content: flex-start;
    gap: 10px;
}

.card {
    width: calc((100% - (10px * 4))/ 5);
    display: flex;
    flex-direction: column;
    position: relative;
}

.card img {
    width: 100%;
}

.cardInfo {
    width: 85%;
    display: flex;
    box-sizing: border-box;
    position: absolute;
    bottom: 15px;
    left: 0;
    background-image: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));
}

.cardLegend {
    width: 100%;
    font-size: 25px;
    padding-left: 2%;
}

.cardIcon {
    width: 40px !important;
    position: absolute;
    top: 50%;
    right: -20px;
    margin-top: -20px;
}

.cardID {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    background-color: #ffffffb3;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 25px;
    padding: 5px;
}