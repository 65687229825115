.title {
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    color: #11111B;
    opacity: 0.7;
    margin-bottom: 24px;
    display: block;
}

.featuresRow {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 22px;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
    border-radius: 13px;
}

.feature {
    width: calc(100% / 3);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-bottom: 16px;
    padding-top: 16px;
}

.featureContent {
    display: flex;
    width: 100%;
    padding-left: 30%;
}

.featureImg {
    display: flex;
    align-items: center;
    margin-right: 14px;
}

.featureDetail {
    display: flex;
    flex-direction: column;
}

.featureLabel {
    font-size: 15px;
    color: #8E8E93;
    line-height: 20px;
}

.featureValue {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
}

.detailTitle {
    display: block;
    margin-bottom: 16px;
    margin-left: 16px;
    color: #11111Bb3;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px
}

.insightsTitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.15px;
    color: #11111B;
    margin-bottom: 29px;
    display: block;
}

.specialTitles {
    display: flex;
    gap: 25px
}

.specialTitles span {
    width: 50%;    
}

.companySelector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.companyButton {
    border: 0.5px solid rgba(0,0,0,.1);
    box-shadow: 0 3px 6px rgb(0 0 0 / 8%);
    background-color: transparent;
    border-radius: 8px;
    font-size: 15px;
    width: 300px;
    padding: 7px 0;
    margin-bottom: 10px;
}

.companyButton:hover {
    color: white;
    background-color: #2864C8;
    border-color: #2864C8;
}