.dialogHeader {
    padding: 25px;
}

.dialogTitle {
    font-weight: bold;
}

.dialogBody {
    display: flex;
    flex-direction: column;
    padding: 25px;
    text-align: left;
}

.dialogBody p {
    margin-bottom: 0;
    margin-top: 20px;
}

.dialogFooter {
    border-top: 1px solid #11111b47;
    display: flex;
    justify-content: flex-end;
    padding: 25px;
    gap: 25px;
}

.dialogFooter button {
    width: 120px;
    background: #2864C8;
    border-radius: 77px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: white;
    box-shadow: none;
    border: none;
    cursor: pointer;
    padding: 14px 0;
}
