.wrapper {
    display: flex;
    background-color: #EAF2FF;
    border-radius: 44px;
    width: fit-content;
    height: fit-content;
}

.wrapper span {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    opacity: 0.5;
    text-transform: uppercase;
}

.mode {
    display: flex;
    padding: 11px  22px;
    cursor: pointer;
}

.mode.active {
    background-color: #2864C8;
    border-radius: 44px;
}

.mode.active span {
    color: white;
    opacity: 1;
}

.singleModeToggle {
    display: flex;
    align-items: center;
    gap: 10px;
}

.singleModeToggle .wrapper {
    background-color: #d3d3d3;
}

.singleModeToggle .wrapper.active {
    background-color: #2864C8;
}

.singleModeToggle .mode {
    display: flex;
    padding: 17px  10px;
    cursor: pointer;
}

.singleModeToggle .mode.active {
    border-radius: 44px;
    background-color: white;
    padding: 15px 15px;
    border: 2px solid #6e6e6e;
}

.singleModeToggle span {
    font-size: 12px;
}
