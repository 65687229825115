.wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    padding: 20px 50px;
    box-sizing: border-box;
    gap: 20px;
    padding-bottom: 0;
}

@media (max-width: 767px) {
    .wrapper {
        padding: 15px;
        padding-bottom: 0;
    }
}
