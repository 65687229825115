.wrapper {
    background: white;
    text-align: left;
    max-width: 1140px;
    margin: auto;
    padding: 20px 0 220px;
}

.pageHeader {
    margin-bottom: 37px;
}

.pageTitle {
    margin-top: 10px;
    text-transform: capitalize;
    margin-bottom: 0;
}

.companySelector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.companyButton {
    border: 0.5px solid rgba(0,0,0,.1);
    box-shadow: 0 3px 6px rgb(0 0 0 / 8%);
    background-color: transparent;
    border-radius: 8px;
    font-size: 15px;
    width: 300px;
    padding: 7px 0;
    margin-bottom: 10px;
}

.companyButton:hover {
    color: white;
    background-color: #2864C8;
    border-color: #2864C8;
}



@media (max-width: 767px) {
    .wrapper {
        padding: 25px 25px 0;
    }
}