.wrapper {
    position: relative;
}

.boxWrapper {
    background: #FFFFFF;
    border: 1px solid #E1E3E6;
    padding: 6px 15px;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 187px;
}

.boxWrapper.main {
    font-size: 24px;
    line-height: 24px;
    padding: 14px 15px 12px
}

.boxWrapper.main span {
    font-size: 15px;
}

.boxWrapper.main svg {
    font-size: 13px;
}

.boxWrapperLabel {
    position: absolute;
    top: -5.5px;
    left: 10px;
    background-color: white;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    opacity: 0.5;
    padding: 0 4px;
}

.datePickerWrapper {
    position: absolute;
    background: #FFFFFF;
    border: 1px solid #E1E3E6;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);;
    border-radius: 13px;
    z-index: 1;
    width: min-content;
}

.buttonsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #E1E3E6;
    padding: 16px;
}

.daysCount {
    font-size: 16px;
    color: #8E8E93;
}

.clearApply {
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 14px;
    line-height: 22px;
    background: #2864C8;
    padding: 8px 30px;
}

.clearApply:hover {
    opacity: 0.7;
}

.rangeCalendarSelector {
    display: flex;
    width: max-content;
}

.rangeCalendarWrapper {
    width: 576px;
    display: flex;
}

.rangeCalendar {
    width: 100%;
    box-sizing: border-box;
}

.calendar {
    padding: 20px 32px;
    box-sizing: border-box;
}

.selectMobile {
    display: none;
}

.selectDesktop .datePicker-wrapper{
    width: 720px;
}

.customPeriods {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.customPeriods span {
    font-weight: bold;
    padding: 15.5px 0;
    padding-left: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    width: 100%;
    box-sizing: border-box;
}

.customPeriods button {
    font-size: 14px;
    padding: 10px 16px;
}

.customPeriods button {
    background: none;
    width: 100%;
    font-style: normal;
    line-height: 18px;
    color: #11111B;
    box-shadow: none;
    border: none;
    cursor: pointer;
    text-align: left;
}

.customPeriods button:hover {
    background: #2864C8;
    color: white;
}

.rangeHelp {
    width: 100%;
    text-align: center;
    padding-top: 10px;
    margin-bottom: -10px;
}

.rangeHelp span {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px
}

.loading {
    top: 0;
    left: 0;
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.9);
}

.selectionLabel {
    display: flex;
}

@media (min-width: 767px) {
    .boxWrapper.range {
        display: inline-flex;
    }
}

@media (max-width: 767px) {
    .wrapper {
        width: calc((100% - 15px) / 2);
        width: 100%;
    }

    .boxWrapper {
        max-width: 100% !important;
        width: 100%;
        color: #11111B;
    }

    .selectMobile {
        max-width: 100%;
    }

    .boxWrapper.main {
        height: 52px;
        font-size: 13px;
        line-height: 24px;
        padding: 14px 15px 12px;
    }

    .boxWrapper.main svg {
        font-size: 13px;
    }
    
    .boxWrapper.main span {
        font-size: 13px;
    }

    .selectDesktop.range {
        display: flex;
    }

    .rangeCalendarWrapper {
        flex-direction: column;
        width: 277px;
    }

    .rangeCalendarSelector {
        flex-direction: column;
        width: -webkit-fill-available;
        align-items: center;
    }

    .datePickerWrapper {
        width: fit-content;
    }

    .customPeriods {
        border-right: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        justify-content: center;
        flex-direction: row;
    }

    .customPeriods button {
        width: auto;
    }

    .selectionLabel {
        font-size: 13px;
    }

    .boxWrapper {
        font-size: 13px;
    }
}