.summaryGrid {
    background: #F7F7FA;
}

.commentedFilter {
    display: flex;
    justify-content: flex-start;
    padding: 0 50px;
}

.commentedFilter label {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    opacity: 0.7;
}

.commentedFilter input {
    margin-right: 11px;
}

@media (max-width: 767px) {
    .commentedFilter {
      padding: 15px 15px;
    }
}