.wrapper {
    display: flex;
    flex-direction: column;
}

.firstRow, .secondRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 37px;
}

.pageControls {
    display: flex;
    justify-content: space-between;
    gap: 12px;
}

.pageTitle {
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 20px;
    line-height: 25.2px;
}

@media (max-width: 767px) {
    .firstRow, .secondRow {
        flex-wrap: wrap;
        gap: 12px;
    }

    .pageBreadcrumb {
        width: 100%;
    }

    .pageControls {
        width: 100%;
        flex-wrap: wrap;
    }

    .pageControls > div {
        width: 100%
    }
}