.background {
    position: fixed;
    top: 48px;
    left: 0;
    width: 100%;
    height: calc(100vh - 48px);
    background-color: #00000099;
    z-index: 2;
    display: flex;
    justify-content: center;
    overflow-y: scroll;
}

.wrapper {
    background-color: white;
    width: fit-content;
    height: fit-content;
    position: relative;
    border-radius: 13px;
    overflow: hidden;
}

.header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E1E3E6;
}

.title {
    display: flex;
}

.specialty {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 20px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding: 0 30px;
    border-right: 1px solid #E1E3E6;
}

.legend {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 24px;
    opacity: 0.4;
    display: flex;
    align-items: center;
    padding: 0 30px;
    border-right: 1px solid #E1E3E6;
}

.qaInspector {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 24px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    border-right: 1px solid #E1E3E6;
}

.closeButton {
    padding: 25px 30px;
    border-left: 1px solid #E1E3E6;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.secondHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 40px;
    border-bottom: 1px solid #E1E3E6;
    position: relative;
    z-index: 2;
    gap: 15px;
}

.toggleScore {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 93px;
}

.body {
    position: relative;
    z-index: 1;
}

.editHeader {
    background: #2864C8;
    border-radius: 77px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: white;
    padding: 11px 0;
    box-shadow: none;
    border: none;
    cursor: pointer;
    min-width: 150px;
    height: 18px;
    box-sizing: content-box;
}

.editHeaderIcon {
    margin-right: 9px;
}

.breadcrumbEdit {
    display: flex;
    gap: 20px;
    align-items: center;
}

.scoreWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.deltaScore {
    font-weight: bold;
}

@media (max-width: 767px) {
    
    .background {
        top: 68px;
    }
    
    .title {
        padding-top: 15px;
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        justify-content: center;
    }

    .closeButton {
        padding: 25px;
        height: auto;
    }

    .secondHeader {
        padding: 20px;
        flex-direction: column;
    }

    .scoreWrapper {
        display: none;
    }

    .toggleScore {
        margin-top: 15px;
    }

    .specialty, .legend, .qaInspector {
        padding: 0;
        text-align: left;
        border-right: none;
    }

    .specialty, .qaInspector {
        font-size: 17px;
    }

    .breadcrumbEdit {
        flex-direction: column;
    }
}