.wrapper {
    border: 1px solid;
    border-radius: 4px;
    padding: 9px 19px 12px 15px;
    height: max-content;
    min-width: 160px;
    box-sizing: border-box;
    text-align: left;
}

.wrapperSimple {
    border: 1px solid;
    border-radius: 12px;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    text-align: center;
}

.main {
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.wrapperSimple .main {
    width: auto;
}

.label {
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    color: #11111B;
    opacity: 0.6;
    margin: 0;
}

.score {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
}

.wrapperSimple .score.big {
    font-size: 20px;
    line-height: 1;
}

.wrapperSimple .score.small {
    font-size: 11px;
}

@media (max-width: 767px) {
    .wrapper {
      width: calc((100% - 15px) / 2);
      margin-bottom: 15px;
    }
  }
  