.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    overflow-y: auto;
}

.wrapper {
    position: relative;
    background-color: white;
    width: 550px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 13px;
    box-shadow: 0px 0px 30px 5px rgb(0 0 0 / 30%);
}