.steps {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    margin-left: -87.5px;
    left: 50%;
}

.step {
    border-radius: 50%;
    border: 1px solid#4472c4;
    font-size: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
}

.stepSeparator {
    border: 1px solid#4472c4;
    width: 50px;
    height: 3px;
    box-sizing: border-box;
}

.activeStep {
    background-color: #4472c4;
    color: white;
    font-weight: bold;
}